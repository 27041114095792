<template>
    <div class="p-6">

        <!-- Loading Spinner -->
        <div v-if="isLoading">
            <div class="mt-5 animate-pulse">
                <div class="h-4 bg-gray-200 rounded w-1/3" style="margin-bottom: 20px;"></div>
                <div class="h-2 bg-gray-200 rounded w-full"></div>
                <div class="h-10 bg-gray-200 rounded mt-2"></div>
                <div class="h-4 bg-gray-200 rounded w-1/3 mt-4"></div>
                <div class="h-2 bg-gray-200 rounded w-full"></div>
                <div class="h-24 bg-gray-200 rounded mt-2"></div>
            </div>
        </div>

        <div class="space-y-8" v-if="!isLoading">
            <!-- Title Field -->
            <div class="flex gap-4">
                <div class="flex flex-col mt-3 w-1/4">
                    <label for="title" class="block text-medium text-left font-semibold text-gray-900">Survey
                        Title</label>
                    <span class="text-sm text-left font-medium text-gray-500">Provide a descriptive title for your
                        survey.</span>
                </div>
                <div class="mt-2 w-full">
                    <input id="title" v-model="title" @input="debounceUpdateTitle" type="text"
                        class="block w-full rounded-lg border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Type here.." />
                </div>
            </div>

            <!-- Context Field -->
            <div class="flex gap-4">
                <div class="flex flex-col mt-3 w-1/4">
                    <label for="context" class="block text-medium text-left font-semibold text-gray-900">Context</label>
                    <span class="text-sm text-left font-medium text-gray-500">Provide Sondar AI with context to increase
                        relevancy of its interations.</span>
                </div>
                <div class="mt-2 w-full">
                    <textarea id="context" v-model="context" rows="3" @input="debounceUpdateContext"
                        class="block w-full rounded-lg border border-gray-300 p-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="Type here.."></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import supabase from "../../../src/supabase";

export default {
    name: "OverviewSection",
    mounted() {
        this.fetchSurvey();
    },
    methods: {
        async fetchSurvey() {
            try {
                const { data, error } = await supabase
                    .from('tenant_surveys')
                    .select("title, llm_context")
                    .eq('id', this.survey_id)
                    .single();

                if (error) {
                    console.error('Error fetching tenant_surveys:', error);
                } else {
                    this.context = data.llm_context;
                    this.title = data.title;
                }
            } catch (err) {
                console.error('Unexpected error:', err);
            } finally {
                this.isLoading = false;
            }
        },
        debounce(func, wait) {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        },
        async updateContext() {
            try {
                const { error } = await supabase
                    .from('tenant_surveys')
                    .update({ llm_context: this.context })
                    .eq('id', this.survey_id);

                if (error) {
                    console.error('Error updating context:', error);
                }
            } catch (err) {
                console.error('Unexpected error updating context:', err);
            }
        },
        async updateTitle() {
            try {
                const { error } = await supabase
                    .from('tenant_surveys')
                    .update({ title: this.title })
                    .eq('id', this.survey_id);

                if (error) {
                    console.error('Error updating title:', error);
                } 
            } catch (err) {
                console.error('Unexpected error updating title:', err);
            }
        },
        debounceUpdateContext: null,
        debounceUpdateTitle: null,
    },
    created() {
        this.debounceUpdateContext = this.debounce(this.updateContext, 2000);
        this.debounceUpdateTitle = this.debounce(this.updateTitle, 2000);
    },
    data() {
        return {
            context: "",
            title: "",
            survey_id: this.$route.params.survey_id,
            isLoading: true,
        };
    },
};
</script>

<style scoped>
button {
    outline: none;
    cursor: pointer;
}
</style>
