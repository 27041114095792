import { defineStore } from "pinia";
import supabase from "../supabase";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: null,
        role: null,
        tenantId: null,
        loading: true,
        tenantName: null,
        isAuthenticated: false,
    }),

    getters: {
        userRole: (state) => state.role,
        getUserTenantId: (state) => state.tenantId,
        getUserTenantName: (state) => state.tenantName,
    },

    actions: {
        async initializeAuth() {
            try {
                this.loading = true;
                // We need to clear the auth if user has access token in url
                const url = new URL(window.location.href).href;
                if (url.includes("access_token") && url.includes("login")) {
                    await this.signOut();
                } else {
                    const { data: { user } } = await supabase.auth.getUser();

                    if (user) {
                        await this.setUser(user);
                    }
                }
            } catch (error) {
                console.error("Error initializing auth:", error);
            } finally {
                this.loading = false;
            }
        },

        async setUser(user) {
            if (!user) {
                this.user = null;
                this.role = null;
                this.tenantId = null;
                this.isAuthenticated = false;
                return;
            }

            const { data: userProfile } = await supabase
                .from("users")
                .select("role_id, roles(name), tenant_id, tenants(name)")
                .eq("id", user.id)
                .single();

            this.user = user;
            this.role = userProfile.roles?.name;
            this.tenantName = userProfile.tenants?.name || null;
            this.tenantId = userProfile?.tenant_id || null;
            this.isAuthenticated = true;
        },

        async signIn({ email, password, rememberMe }) {
            const { data: authData, error: authError } = await supabase.auth
                .signInWithPassword({
                    email,
                    password,
                });

            if (authError) throw authError;

            // Check if user is active in users table
            const { data: userData, error: userError } = await supabase
                .from("users")
                .select("is_active")
                .eq("id", authData.user.id)
                .single();

            if (userError) throw userError;

            if (!userData.is_active) {
                await supabase.auth.signOut();
                throw new Error(
                    "Your account has been deactivated. Please contact your administrator.",
                );
            }

            // Get user role from users table
            const { data: roleData, error: roleError } = await supabase
                .from("users")
                .select(`
                        role_id,
                        tenant_id,
                        roles (
                            name
                        )
                    `)
                .eq("id", authData.user.id)
                .single();

            if (roleError) throw roleError;

            this.user = authData.user;
            this.role = roleData.roles.name;
            this.tenantId = roleData.tenant_id;
            this.isAuthenticated = true;

            if (rememberMe) {
                localStorage.setItem("rememberMe", "true");
            }
        },

        async signOut() {
            const { error } = await supabase.auth.signOut();
            if (error) throw error;

            this.user = null;
            this.role = null;
            this.tenantId = null;
            this.isAuthenticated = false;
        },

        generateRandomKey(length = 40) {
            const characters =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            const charactersLength = characters.length;
            return [...Array(length)]
                .map(() => characters[(Math.random() * charactersLength) | 0])
                .join("");
        },

        async signUp({ email, password, name, organizationName }) {
            const { data: signUpData, error: signUpError } = await supabase.auth
                .signUp({
                    email,
                    password,
                    options: {
                        data: {
                            full_name: name,
                            email_confirmed: false,
                        },
                        emailRedirectTo:
                            `${window.location.protocol}//${window.location.host}/login`,
                    },
                });

            if (signUpError) throw signUpError;

            console.log(signUpData);

            // Create tenant in tenants table
            const { data: tenantData, error: tenantError } = await supabase
                .from("tenants")
                .insert({
                    name: organizationName,
                    api_key: this.generateRandomKey(),
                })
                .select()
                .single();

            if (tenantError) throw tenantError;

            // Create user profile in users table
            const { error: profileError } = await supabase
                .from("users")
                .insert({
                    id: signUpData.user.id,
                    name: name,
                    email: email,
                    role_id: 2,
                    tenant_id: tenantData.id,
                });

            if (profileError) {
                // If profile creation fails, delete the auth user
                await supabase.auth.admin.deleteUser(signUpData.user.id);

                // Delete tenant
                await supabase.from("tenants").delete().eq("id", tenantData.id);
                throw new Error(
                    "Failed to create user profile. Please try again.",
                );
            }
        },
    },
});
