<template>
  <div class="p-6">
    <!-- Popular Templates Section -->
    <div class="max-w-[75rem] w-[75rem] mx-auto mt-5">
      <div class="sm:flex-auto mb-4">
        <h1 class="text-xl text-left font-semibold text-gray-900">Popular Templates</h1>
      </div>
      <div v-if="!isLoadingPopularTemplates" class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
        <div v-for="template in popularTemplates" :key="template.id" @click="openTemplatePreview(template.id)"
          class="group relative bg-white rounded-2xl shadow-sm hover:shadow-md ring-1 ring-gray-200/50 hover:ring-gray-300/50 transition-all duration-200 overflow-hidden cursor-pointer">
          <!-- Template Image/Placeholder -->
          <div v-if="template.thumbnail" class="aspect-[16/9] w-full overflow-hidden">
            <img :src="template.thumbnail" :alt="template.title"
              class="h-full w-full object-cover object-center group-hover:scale-105 transition duration-300 ease-in-out" />
          </div>
          <div v-else class="aspect-[16/9] w-full bg-gradient-to-br from-gray-50 to-gray-100">
            <div class="flex items-center justify-center w-full h-full">
              <div class="flex flex-col items-center space-y-2">
                <svg class="w-12 h-12 text-gray-300 transform group-hover:scale-110 transition-transform duration-300"
                  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path
                    d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.88a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            </div>
          </div>

          <!-- Template Info -->
          <div class="py-5 px-3">
            <div class="flex flex-col justify-between h-full">
              <div class="flex-1 space-y-2">
                <h3
                  class="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200 text-left">
                  {{ template.title }}
                </h3>
                <p class="text-sm text-gray-500 line-clamp-2 leading-relaxed text-left">
                  {{ template.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="grid grid-cols-2 gap-4 sm:grid-cols-4">
        <!-- Loop for Cards -->
        <div v-for="(card, index) in cards" :key="index" class="flex flex-col items-center space-y-2">
          <!-- Card Skeleton -->
          <div class="h-32 w-full overflow-hidden rounded-lg bg-gray-100">
            <div class="animate-pulse flex items-center justify-center w-full h-full">
              <div class="flex flex-col items-center space-y-2">
                <svg class="w-10 h-10 text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor" viewBox="0 0 20 18">
                  <path
                    d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
                <div class="text-xs text-gray-300"></div>
              </div>
            </div>
          </div>
          <!-- Title Placeholder -->
          <div class="h-4 w-32 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    </div>

    <!-- Header with buttons -->
    <div class="max-w-[75rem] mx-auto mt-10">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl text-left font-semibold text-gray-900">Surveys</h1>
        </div>
        <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center gap-3 mb-4">
          <!-- Add filters here -->
          <div class="flex items-center space-x-4">
            <!-- Status filter -->
            <div class="relative flex items-center">
              <div class="relative">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                  <FunnelIcon class="h-4 w-4 text-gray-500" aria-hidden="true" />
                </div>
                <select v-model="selectedStatus"
                  class="appearance-none block w-40 rounded-md border-0 py-1.5 pl-8 pr-10 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600">
                  <option value="all">All Status</option>
                  <option value="draft">Draft</option>
                  <option value="in_progress">In Progress</option>
                  <option value="pause">Paused</option>
                  <option value="archive">Archived</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <svg class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clip-rule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <!-- Existing buttons -->
          <button type="button" @click="exploreTemplates"
            class="block rounded-md bg-white px-3 py-2 text-center text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Explore Templates
          </button>
          <button type="button" @click="showSurveyPopup = true"
            class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            New Survey
          </button>
        </div>
      </div>
    </div>

    <!-- Survey List Section -->
    <div class="max-w-[75rem] mx-auto">
      <!-- Loading State -->
      <div v-if="isLoading">
        <div class="animate-pulse space-y-4 w-full">
          <div v-for="i in 5" :key="i" class="bg-white px-4 py-6 shadow rounded-lg sm:px-6">
            <div class="flex items-center justify-between">
              <div class="space-y-3 flex-1">
                <div class="h-4 bg-gray-200 rounded w-1/4"></div>
                <div class="h-3 bg-gray-200 rounded w-1/6"></div>
              </div>
              <div class="h-8 w-8 bg-gray-200 rounded-full"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Empty State -->
      <div v-else-if="surveys.length === 0" class="text-center mt-8 bg-white py-12 px-6 shadow rounded-lg sm:px-12">
        <h3 class="mt-2 text-sm font-semibold text-gray-900">No surveys</h3>
        <p class="mt-1 text-sm text-gray-500">Get started by creating a new survey.</p>
        <div class="mt-6">
          <button type="button" @click="showSurveyPopup = true"
            class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New Survey
          </button>
        </div>
      </div>

      <!-- Add this new section for filtered empty state -->
      <div v-else-if="filteredSurveys.length === 0"
        class="text-center mt-8 bg-white py-12 px-6 shadow rounded-lg sm:px-12">
        <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-gray-100">
          <MagnifyingGlassIcon class="h-6 w-6 text-gray-600" aria-hidden="true" />
        </div>
        <h3 class="mt-4 text-sm font-semibold text-gray-900">No surveys found</h3>
        <p class="mt-2 text-sm text-gray-500">
          No surveys match your current filters. Try adjusting your search criteria or
          <button @click="clearFilters" class="text-indigo-600 hover:text-indigo-500 font-medium">
            clear all filters
          </button>.
        </p>
      </div>

      <!-- Survey List -->
      <div v-else class="flow-root">
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="max-w-[90rem] mx-auto">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="w-[500px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Title
                    </th>
                    <th scope="col" class="w-[120px] py-3.5 pl-5 pr-3 text-left text-sm font-semibold text-gray-900">Status
                    </th>
                    <th scope="col" class="w-[150px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Responses</th>
                    <th scope="col" class="w-[150px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created
                      By</th>
                    <th scope="col" class="w-[200px] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last
                      Updated</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-for="survey in paginatedSurveys" :key="survey.id" class="hover:bg-gray-50 cursor-pointer" @click="editSurvey(survey.id)">
                    <td class="w-[500px] px-3 py-4 text-left">
                      <div class="flex flex-col">
                        <div class="font-medium text-gray-900">
                          {{ survey.title }}
                        </div>
                        <div v-if="userRole === 'system_admin'" class="mt-1 text-xs text-gray-500">
                          {{ survey.tenant }} ({{ survey.tenantId }})
                        </div>
                      </div>
                    </td>
                    <td class="w-[120px] whitespace-nowrap py-4 pl-5 pr-3 text-left">
                      <span class="'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium'">
                        {{ statusMap[survey.status] || survey.status }}
                      </span>
                    </td>
                    <td class="w-[150px] whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                      <div class="flex items-center">
                        <ChartBarIcon class="mr-1.5 h-4 w-4 flex-shrink-0" />
                        <span>{{ survey.responses }}{{ survey.responseLimit ? ` / ${survey.responseLimit}` : ''
                          }}</span>
                      </div>
                    </td>
                    <td class="w-[150px] whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                      {{ survey.createdBy || 'Me' }}
                    </td>
                    <td class="w-[200px] whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                      {{ survey.lastUpdated || '5 days ago' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div v-if="filteredSurveys.length > pageSize" class="max-w-[90rem] mx-auto mt-4">
        <div class="flex flex-1 justify-between sm:hidden">
          <button @click="prevPage" :disabled="currentPage === 1"
            class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
            Previous
          </button>
          <button @click="nextPage" :disabled="currentPage === totalPages"
            class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
            Next
          </button>
        </div>
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p class="text-sm text-gray-700">
              Showing
              <span class="font-medium">{{ ((currentPage - 1) * pageSize) + 1 }}</span>
              to
              <span class="font-medium">{{ Math.min(currentPage * pageSize, filteredSurveys.length) }}</span>
              of
              <span class="font-medium">{{ filteredSurveys.length }}</span>
              results
            </p>
          </div>
          <div>
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button @click="prevPage" :disabled="currentPage === 1"
                class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
                <span class="sr-only">Previous</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>

              <!-- Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" -->
              <button v-for="page in totalPages" :key="page" @click="currentPage = page" :class="[
                page === currentPage
                  ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                  : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
              ]">
                {{ page }}
              </button>

              <button @click="nextPage" :disabled="currentPage === totalPages"
                class="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:opacity-50 disabled:cursor-not-allowed">
                <span class="sr-only">Next</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- Create Survey Modal -->
    <div v-if="showSurveyPopup" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div>
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Create a New Survey</h3>

                <div class="mt-4 mb-16">
                  <div class="mb-8">
                    <label for="survey-name" class="block text-sm font-medium leading-6 text-gray-900">Survey
                      Name</label>
                    <div class="mt-2">
                      <input type="text" id="survey-name" v-model="newSurveyName"
                        placeholder="e.g. Customer Satisfaction Survey" class="input-sondar" />
                    </div>
                  </div>

                  <div class="mb-4" v-if="userRole === 'system_admin'">
                    <label for="tenant-id" class="block text-sm font-medium leading-6 text-gray-900">Tenant</label>
                    <div class="mt-2">
                      <select id="tenant-id" v-model="selectedTenantId" class="select-sondar">
                        <option value="" disabled>Select tenant</option>
                        <option v-for="tenant in tenants" :key="tenant.id" :value="tenant.id">
                          {{ tenant.name }} ({{ tenant.id }})
                        </option>
                      </select>
                    </div>
                  </div>

                  <p v-if="errorMessage" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
                </div>
              </div>
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button type="button" @click="resetModal()" class="btn-secondary-sondar">
                Cancel
              </button>
              <button type="button" @click="createSurvey" class="btn-primary-sondar">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Templates Modal -->
    <TransitionRoot as="template" :show="showTemplatesModal">
      <Dialog as="div" class="relative z-50" @close="showTemplatesModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
          leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10">
          <div class="flex min-h-screen items-center justify-center p-8">
            <TransitionChild as="template" enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative w-[80%] h-[80vh] bg-white shadow-xl rounded-lg overflow-hidden">
                <div class="h-full flex flex-col">
                  <!-- Modal header -->
                  <div class="flex justify-between items-center px-6 py-4 border-b border-gray-200">
                    <DialogTitle as="h3" class="text-lg font-semibold leading-6 text-gray-900">
                      Explore Templates
                    </DialogTitle>
                    <button type="button"
                      class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      @click="showTemplatesModal = false">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  <!-- Modal content -->
                  <div class="flex-1 overflow-y-auto px-6 py-4 h-full">
                    <TemplatesList />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import {
  ChartBarIcon,
  PlusIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline"
import supabase from "../../../src/supabase"
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  DialogTitle,
} from '@headlessui/vue'
import TemplatesList from './TemplatesList.vue'

export default {
  components: {
    ChartBarIcon,
    PlusIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MagnifyingGlassIcon,
    FunnelIcon,
    XMarkIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    DialogTitle,
    TemplatesList
  },
  data() {
    return {
      currentUserId: null,
      userRole: null,
      tenantId: null,
      surveys: [],
      currentPage: 1,
      showSurveyPopup: false,
      newSurveyName: "",
      errorMessage: "",
      selectedTenantId: "",
      tenants: [],
      pageSize: 10,
      isLoading: true,
      popularTemplates: [],
      cards: Array(4).fill(null),
      isLoadingPopularTemplates: true,
      selectedStatus: 'all',
      showTemplatesModal: false,
      statusMap: {
        draft: 'Draft',
        in_progress: 'In Progress',
        archive: 'Archived',
        pause: 'Paused'
      },
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredSurveys.length / this.pageSize);
    },
    filteredSurveys() {
      let filtered = [...this.surveys];

      // Filter by status
      if (this.selectedStatus !== 'all') {
        filtered = filtered.filter(survey =>
          survey.status === this.selectedStatus
        );
      }

      return filtered;
    },
    paginatedSurveys() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredSurveys.slice(start, end);
    },
    shouldShowAbove() {
      // Get the viewport height
      const viewportHeight = window.innerHeight;
      // Get the element's position if needed
      // const elementPosition = this.$el.getBoundingClientRect().top;

      // For now, let's show above if we're in the bottom 25% of the viewport
      return viewportHeight - this.$el.getBoundingClientRect().top < viewportHeight * 0.25;
    }
  },
  methods: {
    async fetchSurveys() {
      this.isLoading = true;
      try {
        let query = supabase
          .from("tenant_surveys")
          .select(`
            id, 
            tenant_id, 
            tenants(name), 
            title,
            status,
            response_limit,
            created_at,
            updated_at,
            responses:responses(id, response_messages:response_messages(id))
          `)
          .order("created_at", { ascending: false });

        if (this.tenantId) {
          query.eq("tenant_id", this.tenantId);
        }

        const { data, error } = await query;

        if (error) {
          console.error("Error fetching surveys:", error);
          return;
        }

        this.surveys = data.map((survey) => ({
          id: survey.id,
          title: survey.title,
          status: survey.status || 'draft',
          responseLimit: survey.response_limit,
          responses: survey.responses
            ? survey.responses.filter(
              (response) =>
                response.response_messages &&
                response.response_messages.length > 0
            ).length
            : 0,
          tenant: survey.tenants?.name,
          tenantId: survey.tenant_id,
          createdBy: 'Me',
          created_at: survey.created_at,
          updated_at: survey.updated_at,
          lastUpdated: new Date(survey.updated_at).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })
        }));
      } catch (err) {
        console.error("Unexpected error:", err);
      } finally {
        this.isLoading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async fetchTenants() {
      try {
        const { data, error } = await supabase
          .from("tenants")
          .select("id, name");
        if (error) {
          throw error;
        }
        this.tenants = data;
      } catch (error) {
        console.error("Error fetching tenants:", error);
      }
    },
    async createSurvey() {
      if (
        this.newSurveyName === "" ||
        (this.userRole === "system_admin" && this.selectedTenantId === "")
      ) {
        this.errorMessage = "Please fill out all required fields.";
        return;
      }
      await this.createSurveyInDb();
      this.resetModal();
      this.fetchSurveys();
    },
    async createSurveyInDb() {
      try {
        const { data, error } = await supabase
          .from("tenant_surveys")
          .insert([
            {
              tenant_id: this.selectedTenantId,
              trigger_events: [],
              conditions: [],
              user_attrs: [],
              delay_seconds: 0,
              can_dismiss: true,
              llm_context: "",
              status: "draft",
              widget_position: "bottom_right",
              title: this.newSurveyName,
              trigger_type: "page_visit",
              response_limit: null,
            },
          ])
          .select()
          .single();

        if (error) {
          throw error;
        }
        await this.createMessageBlockIndDb(data.id);
        this.errorMessage = "";
        this.showSurveyPopup = false;
      } catch (error) {
        this.errorMessage = "Failed to create survey. Please try again later.";
      }
    },
    async createMessageBlockIndDb(survey_id) {
      const block = {
        type: "message",
        title: "Untitled",
        button_label: "",
        button_url: "",
        position: 1,
        required: false,
        survey_id: survey_id,
      };
      try {
        const { error } = await supabase.from("survey_blocks").insert(block);
        if (error) {
          console.error("Error syncing blocks:", error);
        }
      } catch (err) {
        console.error("Unexpected error:", err);
      }
    },
    editSurvey(id) {
      this.$router.push(`/survey/edit/${id}`);
    },
    resetModal() {
      this.newSurveyName = "",
        this.selectedTenantId = "",
        this.showSurveyPopup = false
    },
    exploreTemplates() {
      this.showTemplatesModal = true;
    },
    async fetchPopularTemplates() {
      try {
        this.isLoadingPopularTemplates = true;
        const { data, error } = await supabase
          .from('tenant_surveys')
          .select(`
            id,
            title,
            metadata
          `)
          .eq('is_popular', true)
          .limit(4);

        if (error) {
          console.error("Error fetching popular templates:", error);
          return;
        }

        this.popularTemplates = data.map(template => ({
          id: template.id,
          title: template.title,
          thumbnail: template.metadata?.thumbnail || null,
          description: template.metadata?.description || 'No description available',
        }));
      } catch (err) {
        console.error("Unexpected error fetching templates:", err);
      } finally {
        this.isLoadingPopularTemplates = false;
      }
    },
    clearFilters() {
      this.selectedStatus = 'all';
      this.currentPage = 1;
    },
    openTemplatePreview(templateId) {
      this.$router.push(`/templates/${templateId}`);
    },
  },
  async mounted() {
    const authStore = useAuthStore();
    this.currentUserId = authStore.user.id;
    this.userRole = authStore.role;
    this.tenantId = authStore.tenantId;
    await Promise.all([
      this.fetchSurveys(),
      this.fetchPopularTemplates()
    ]);
    if (this.userRole === "system_admin") {
      await this.fetchTenants();
    }
  },
  watch: {
    selectedStatus() {
      this.currentPage = 1;
    },
    tenantId: {
      immediate: true,
      handler(newTenantId) {
        if (newTenantId && !this.selectedTenantId) {
          this.selectedTenantId = newTenantId;
        }
      }
    }
  },
};
</script>

<style></style>
