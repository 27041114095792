<template>
  <div class="p-6 max-w-[75rem] w-[75rem] mx-auto w-full">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Events</h2>
      <button type="button" @click="showPopup = true"
        class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        New Event
      </button>
    </div>

    <div v-if="isLoading">
      <div class="mt-8 flow-root animate-pulse">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-20 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-16 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="px-3 py-3.5">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span class="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="i in 4" :key="i">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">
                    <div class="h-4 w-32 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-48 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-4 w-24 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-6 w-24 bg-gray-300 rounded"></div>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4">
                    <div class="h-5 w-5 bg-gray-300 rounded-full ml-auto"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="events.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">
          There are no custom events. Create one now!
        </p>
      </div>

      <div v-else class="overflow-hidden">
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table class="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                    <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Occurrences</th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(event, index) in paginatedEvents" :key="index" class="hover:bg-gray-50">
                    <td class="whitespace-nowrap text-left py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">
                      {{ event.name }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ event.description || '-' }}
                    </td>
                    <td class="whitespace-nowrap text-left px-3 py-4 text-sm text-gray-500">
                      {{ formatCreatedAt(event.createdAt) }}
                    </td>
                    <td class="whitespace-nowrap text-center px-3 py-4 text-sm text-gray-500">
                      {{ event.occurrenceCount }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading && events.length > itemsPerPage" class="flex justify-between items-center mt-4">
      <button type="button" @click="previousPage" :disabled="currentPage === 1"
        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
        Previous
      </button>
      <span class="text-sm font-medium text-gray-700">
        Page {{ currentPage }} of {{ totalPages }}
      </span>
      <button type="button" @click="nextPage" :disabled="currentPage === totalPages"
        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
        Next
      </button>
    </div>

    <!-- Create New Event Modal -->
    <div v-if="showPopup" class="relative z-10">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto text-left">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div>
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  Create a New Event
                </h3>

                <div class="mt-6 mb-16">
                  <div class="mb-4">
                    <label for="event-name" class="block text-sm font-medium leading-6 text-gray-900">Event Name</label>
                    <div class="mt-2">
                      <input id="event-name" v-model="newEventName" type="text" placeholder="e.g. purchaseComplete"
                        class="input-sondar" />
                      <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                        {{ errorMessage }}
                      </p>
                    </div>
                  </div>

                  <div class="mb-4">
                    <label for="event-description"
                      class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                    <div class="mt-2">
                      <input id="event-description" v-model="newEventDescription" type="text"
                        placeholder="e.g. User has completed a purchase" class="input-sondar" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button type="button" @click="clearModal()" class="btn-secondary-sondar">
                Cancel
              </button>
              <button type="button" @click="createEvent" class="btn-primary-sondar">
                Create
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from "../../../src/supabase";
import { format } from "date-fns";
import { useAuthStore } from '@/stores/auth'

export default {
  components: {
  },
  data() {
    return {
      currentUserId: null,
      userRole: null,
      tenantId: null,
      events: [],
      currentPage: 1,
      itemsPerPage: 10,
      isLoading: true,
      showPopup: false,
      newEventName: "",
      newEventDescription: "",
      errorMessage: "",
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.events.length / this.itemsPerPage);
    },
    paginatedEvents() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.events.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    async fetchEvents() {
      this.isLoading = true;
      try {
        // Fetch events
        let query = supabase
          .from("tenant_event")
          .select("id, event_name, created_at, description")
          .order("created_at", { ascending: false });

        if (this.tenantId) {
          query.eq("tenant_id", this.tenantId);
        }

        const { data: eventData, error: eventError } = await query;

        if (eventError) {
          console.error("Error fetching events:", eventError);
          return;
        }

        // Fetch metrics
        let metricsQuery = supabase
          .from("event_metrics")
          .select("event_name, occurrence_count");

        if (this.tenantId) {
          metricsQuery = metricsQuery.eq("tenant_id", this.tenantId);
        }

        const { data: metricsData, error: metricsError } = await metricsQuery;

        if (metricsError) {
          console.error("Error fetching metrics:", metricsError);
        }

        // Compute the total occurrence count for each event_name
        const metricsMap = (metricsData || []).reduce((acc, metric) => {
          if (!acc[metric.event_name]) {
            acc[metric.event_name] = 0;
          }
          acc[metric.event_name] += metric.occurrence_count;
          return acc;
        }, {});

        // Map events with their metrics
        this.events = eventData.map((event) => ({
          id: event.id,
          name: event.event_name,
          createdAt: event.created_at,
          description: event.description,
          occurrenceCount: metricsMap[event.event_name] || 0,
          showMenu: false,
        }));
      } catch (err) {
        console.error("Unexpected error:", err);
      } finally {
        this.isLoading = false;
      }
    },
    formatCreatedAt(date) {
      return format(new Date(date), "MMM do, yyyy");
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async createEvent() {
      if (!this.newEventName.trim() || this.newEventName.includes(" ")) {
        this.errorMessage =
          "Event name is required. Must not contain any spaces.";
        return;
      }

      try {
        const { error } = await supabase.from("tenant_event").insert([
          {
            event_name: this.newEventName.trim(),
            description: this.newEventDescription.trim() || null,
            tenant_id: this.tenantId,
          },
        ]);

        if (error) {
          console.error("Error creating event:", error);
          this.errorMessage = "Failed to create event. Try again later.";
          return;
        }

        this.newEventName = "";
        this.newEventDescription = "";
        this.errorMessage = "";
        this.showPopup = false;
        this.fetchEvents();
      } catch (err) {
        console.error("Unexpected error:", err);
        this.errorMessage = "An unexpected error occurred.";
      }
    },
    async deleteEvent(id) {
      console.log(id);
    },
    clearModal() {
      this.newEventName = '',
        this.newEventDescription = '',
        this.errorMessage = '',
        this.showPopup = ''
    }
  },
  async mounted() {
    const authStore = useAuthStore();
    this.currentUserId = authStore.user.id;
    this.userRole = authStore.role;
    this.tenantId = authStore.tenantId;
    await this.fetchEvents();
  },
};
</script>
