<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Sign in to your account
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <!-- Success message -->
      <div v-if="containsAccessToken" class="mb-6 rounded-md bg-green-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-green-800">
              Your account is activated. Please sign in to continue.
            </p>
          </div>
        </div>
      </div>

      <!-- Access Revoked Error -->
      <div v-if="accessRevokedError" class="rounded-md bg-red-50 p-4 mb-3">
        <div class="flex">
          <div class="ml-3">
            <div class="mt-2 text-sm text-red-700">
              <p>Your account has been deactivated. Please contact your administrator for assistance.</p>
            </div>
          </div>
        </div>
      </div>

      <form @submit.prevent="handleSignIn" class="space-y-6">
        <div>
          <label for="email" class="block text-sm font-medium leading-6 text-gray-900 text-left">Email address</label>
          <div class="mt-2">
            <input 
              v-model="email" 
              type="email" 
              name="email" 
              id="email" 
              autocomplete="email" 
              required
              placeholder="you@example.com"
              @input="validateEmail"
              class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <p v-if="emailError" class="mt-1 text-left text-sm text-red-600">{{ emailError }}</p>
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium leading-6 text-gray-900 text-left">Password</label>
          <div class="mt-2">
            <input 
              v-model="password" 
              type="password" 
              name="password" 
              id="password" 
              autocomplete="current-password" 
              required
              placeholder="••••••••"
              @input="validatePassword"
              class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <p v-if="passwordError" class="mt-1 text-left text-sm text-red-600">{{ passwordError }}</p>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input 
              id="remember-me" 
              name="remember-me" 
              type="checkbox" 
              v-model="rememberMe"
              class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" 
            />
            <label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-900">Remember me</label>
          </div>

          <div class="text-sm leading-6">
            <router-link to="/forgot-password" class="font-semibold text-indigo-600 hover:text-indigo-500">
              Forgot password?
            </router-link>
          </div>
        </div>

        <div>
          <button 
            type="submit"
            :disabled="isLoading || !isFormValid"
            class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            <template v-if="isLoading">
              <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Signing in...
            </template>
            <template v-else>
              Sign in
            </template>
          </button>
        </div>

        <p v-if="formError" class="mt-4 text-sm text-red-600 text-center">{{ formError }}</p>

        <p class="mt-10 text-center text-sm text-gray-500">
          Don't have an account?
          <router-link to="/signup" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
            Sign up
          </router-link>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'AuthSignIn',
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      emailError: '',
      passwordError: '',
      formError: '',
      isLoading: false,
      isEmailValid: false,
      accessRevokedError: false,
    };
  },
  computed: {
    containsAccessToken() {
      const url = new URL(window.location.href).href;
      return url.includes("access_token");
    },
    isFormValid() {
      return this.isEmailValid && 
        this.password.length >= 6 && 
        !this.emailError && 
        !this.passwordError;
    }
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailRegex.test(this.email);
      this.emailError = isValid ? '' : 'Please enter a valid email address';
      this.isEmailValid = isValid;
    },
    validatePassword() {
      if (this.password.length < 6) {
        this.passwordError = 'Password must be at least 6 characters';
      } else {
        this.passwordError = '';
      }
    },
    async handleSignIn() {
      this.emailError = '';
      this.passwordError = '';
      this.formError = '';
      this.accessRevokedError = false;

      if (!this.email) {
        this.emailError = 'Email is required';
        return;
      }
      if (!this.password) {
        this.passwordError = 'Password is required';
        return;
      }

      try {
        this.isLoading = true;
        const authStore = useAuthStore();
        await authStore.signIn({
          email: this.email,
          password: this.password,
          rememberMe: this.rememberMe,
        });

        this.$router.push('/');
      } catch (err) {
        if (err.message.includes('deactivated')) {
          this.accessRevokedError = true;
        } else {
          this.formError = err.message || 'An unexpected error occurred. Please try again later.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    password() {
      this.validatePassword();
    }
  }
};
</script>
