import { createRouter, createWebHistory } from "vue-router";
import ShareableSurveyView from "@/components/ShareableSurveyView.vue";
import AuthSignIn from "@/components/adminComponents/AuthSignIn.vue";
import AdminDashboard from "@/components/adminComponents/AdminDashboard.vue";
import ResetPassword from "@/components/adminComponents/ResetPassword.vue";
import ForgotPassword from "@/components/adminComponents/ForgotPassword.vue";
import PreviewTemplate from "@/components/adminComponents/PreviewTemplate.vue";
import TemplatesList from "@/components/adminComponents/TemplatesList.vue";
import AuthSignUp from "@/components/adminComponents/AuthSignUp.vue";
import NotFound from "@/components/NotFound.vue";
import { useAuthStore } from "@/stores/auth";

const routes = [
  {
    path: "/survey/:survey_id",
    name: "ShareableSurveyView",
    component: ShareableSurveyView,
    props: (route) => ({
      surveyId: route.params.survey_id,
      isPreview: route.query.isPreview === "true",
    }),
  },
  {
    path: "/templates",
    name: "templatesList",
    component: TemplatesList,
    props: true,
  },
  {
    path: "/templates/:templateId",
    name: "previewTemplate",
    component: PreviewTemplate,
    props: true,
  },
  {
    path: "/login",
    name: "SignInView",
    component: AuthSignIn,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/events",
    name: "EventsList",
    component: AdminDashboard,
  },
  {
    path: "/attributes",
    name: "AttributesList",
    component: AdminDashboard,
  },
  {
    path: "/survey/edit/:survey_id",
    name: "SurveyBuilder",
    component: AdminDashboard,
  },
  {
    path: "/admin-settings",
    name: "AdminSettings",
    component: AdminDashboard,
  },
  {
    path: "/tenant-settings",
    name: "TenantSettings",
    component: AdminDashboard,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/signup",
    name: "SignUpView",
    component: AuthSignUp,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Wait for auth to initialize on first navigation
  if (authStore.loading) {
    await authStore.initializeAuth();
  }

  if (
    authStore.isAuthenticated &&
    (to.path === "/login" || to.path === "/forgot-password" ||
      to.path === "/signup")
  ) {
    return next({ name: "AdminDashboard" });
  }

  if (
    to.path !== "/login" &&
    to.path !== "/forgot-password" &&
    to.path !== "/signup" &&
    to.path !== "/templates" &&
    to.path !== "/templates/:templateId" &&
    !authStore.isAuthenticated
  ) {
    return next({ name: "SignInView" });
  }
  next();
});

export default router;
