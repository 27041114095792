<template>
    <div class="p-6">
        <div class="flex items-center gap-5 mb-5">
            <div class="relative flex items-center space-x-4">
                <select
                    class="text-gray-700 focus:outline-none border border-gray-400 rounded-md px-4 py-2 text-sm font-medium"
                    v-model="filter" @change="setFilter">
                    <option v-for="option in filterOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>

                <div v-if="filter === 'custom'" class="flex items-center space-x-4">
                    <div class="flex items-center space-x-2">
                        <label class="block text-sm font-medium text-gray-700 whitespace-nowrap">Start Date: </label>
                        <input type="date" v-model="customStartDate"
                            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 px-4 py-1 focus:ring-indigo-500 sm:text-sm"
                            @change="setFilter" />
                    </div>

                    <div class="flex items-center space-x-2">
                        <label class="block text-sm font-medium text-gray-700 whitespace-nowrap">End Date: </label>
                        <input type="date" v-model="customEndDate"
                            class="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 px-4 py-1 focus:ring-indigo-500 sm:text-sm"
                            @change="setFilter" />
                    </div>
                </div>
            </div>

            <div class="flex items-center justify-between">
                <button @click="showAppliedFilters = !showAppliedFilters"
                    class="px-6 pr-3 py-2 bg-indigo-500 hover:bg-indigo-600 text-white font-medium text-sm rounded-md shadow focus:outline-none transition duration-300 ease-in-out">
                    Filters
                    <svg v-if="!showAppliedFilters" class="w-4 h-4 inline-block ml-2" xmlns="http://www.w3.org/2000/svg"
                        fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else class="w-4 h-4 inline-block ml-2" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                </button>
                <div v-if="filters.applied.length > 0" class="relative flex items-center space-x-4">
                    <span
                        class="flex items-center space-x-2 text-sm cursor-pointer px-4 py-2 rounded-md transition duration-300 ease-in-out"
                        @click="clearAllFilters">
                        <span>Clear Filters</span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>

        <!-- Filters -->
        <div class="p-6 bg-white rounded-lg shadow-md w-full border border-gray-200 mb-5" v-if="showAppliedFilters">
            <div class="flex justify-between items-center space-x-2 mb-4">
                <h2 class="text-lg font-semibold text-gray-800">Filters</h2>
                <div class="flex items-center space-x-2">
                    <!-- <label class="text-sm font-medium text-gray-700">Match with:</label>
                    <select v-model="filters.matchOption" class="rounded-lg border border-gray-300 p-2 text-sm">
                        <option value="all">All Attributes</option>
                        <option value="any">Any Attribute</option>
                    </select> -->
                    <svg xmlns="http://www.w3.org/2000/svg" @click="showAppliedFilters = false"
                        class="h-4 w-4 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div v-if="filters.applied.length === 0"
                class="text-gray-500 bg-gray-100 rounded-lg p-3 text-sm italic mb-4">
                No filters applied.
            </div>
            <div v-for="(filter, index) in filters.applied" :key="index"
                class="mb-4 flex items-center w-full space-x-2">
                <select v-model="filter.type"
                    class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-1/4">
                    <option v-for="option in filters.options" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>

                <template v-if="filter.type === 'device'">
                    <select v-model="filter.criteria"
                        class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                        <option
                            v-for="criteria in filters.options.find(opt => opt.value === 'device')?.options[0].criteria"
                            :key="criteria" :value="criteria">
                            {{ criteria }}
                        </option>
                    </select>
                </template>

                <template v-if="filter.type === 'language'">
                    <select v-model="filter.criteria"
                        class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                        <option v-for="lang in filters.options.find(opt => opt.value === 'language')?.options"
                            :key="lang.value" :value="lang.label">
                            {{ lang.label }}
                        </option>
                    </select>
                </template>

                <template v-if="filter.type !== 'device' && filter.type !== 'language'">
                    <template v-if="filters.options.find(opt => opt.value === filter.type)?.options.length">
                        <select v-model="filter.option"
                            class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                            <option v-for="option in filters.options.find(opt => opt.value === filter.type)?.options"
                                :key="option.value" :value="option">
                                {{ option.label }}
                            </option>
                        </select>
                    </template>

                    <template
                        v-if="(filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.operators || []).length">
                        <select v-model="filter.operator"
                            class="border border-gray-300 rounded-md text-center px-3 py-2 text-sm text-gray-800 focus:outline-none w-1/3">
                            <option
                                v-for="option in (filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.operators || [])"
                                :key="option" :value="option">
                                {{ option }}
                            </option>
                        </select>
                    </template>

                    <template
                        v-if="filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria">
                        <template
                            v-if="Array.isArray(filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria)">
                            <select v-model="filter.criteria"
                                class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full">
                                <option
                                    v-for="criteria in filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria"
                                    :key="criteria" :value="criteria">
                                    {{ criteria }}
                                </option>
                            </select>
                        </template>
                        <template v-else>
                            <input v-model="filter.criteria"
                                :type="filters.options.find(opt => opt.value === filter.type)?.options.find(opt => opt.value === filter.option?.value)?.criteria"
                                class="border border-gray-300 rounded-md px-3 py-2 text-sm text-gray-800 focus:outline-none w-full"
                                placeholder="Enter criteria" />
                        </template>
                    </template>
                </template>

                <button @click="removeFilter(index)" class="text-gray-500 hover:text-gray-700">
                    <TrashIcon class="w-5 h-5 text-indigo-500" />
                </button>
            </div>

            <div class="flex items-center space-x-4 mt-3">
                <button @click="addFilter"
                    class="border border-gray-300 bg-white text-gray-700 text-sm px-6 py-2 rounded-md hover:bg-gray-100 focus:outline-none">
                    Add Filter
                </button>
                <button @click="applyFilters" v-if="filters.applied.length > 0"
                    class="bg-indigo-500 text-white text-sm px-6 py-2 rounded-md hover:bg-indigo-600 focus:outline-none">
                    Apply
                </button>
            </div>
        </div>

        <!-- Loading -->
        <div v-if="isLoading">
            <div class="min-h-screen animate-pulse space-y-6 bg-gray-50 p-6">
                <div class="rounded-lg bg-white p-4 shadow">
                    <div class="flex items-center justify-between">
                        <div class="h-6 w-16 rounded bg-gray-200"></div>
                        <div class="h-4 w-20 rounded bg-gray-200"></div>
                    </div>
                    <div class="mt-4 h-6 w-3/4 rounded bg-gray-200"></div>
                    <div class="mt-6 space-y-2">
                        <div class="flex items-end space-x-4">
                            <div class="h-16 w-1/4 rounded bg-gray-300"></div>
                            <div class="h-16 w-1/4 rounded bg-gray-300"></div>
                            <div class="h-12 w-1/4 rounded bg-gray-300"></div>
                        </div>
                        <div class="mt-2 flex justify-between space-x-4 text-sm text-gray-300">
                            <div class="h-4 w-20 rounded bg-gray-200"></div>
                            <div class="h-4 w-20 rounded bg-gray-200"></div>
                            <div class="h-4 w-20 rounded bg-gray-200"></div>
                        </div>
                    </div>
                </div>

                <div class="rounded-lg bg-white p-4 shadow">
                    <div class="flex items-center justify-between">
                        <div class="h-6 w-24 rounded bg-gray-200"></div>
                        <div class="h-4 w-20 rounded bg-gray-200"></div>
                    </div>
                    <div class="mt-4 h-6 w-3/4 rounded bg-gray-200"></div>
                    <div class="mt-6 space-y-2">
                        <div class="h-6 rounded bg-gray-300"></div>
                        <div class="h-6 rounded bg-gray-300"></div>
                        <div class="h-6 rounded bg-gray-300"></div>
                        <div class="h-6 rounded bg-gray-300"></div>
                    </div>
                    <div class="mt-2 flex justify-between space-x-4 text-sm text-gray-300">
                        <div class="h-4 w-24 rounded bg-gray-200"></div>
                        <div class="h-4 w-24 rounded bg-gray-200"></div>
                        <div class="h-4 w-24 rounded bg-gray-200"></div>
                        <div class="h-4 w-24 rounded bg-gray-200"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isLoading">
            <!-- Section Questions -->
            <div v-if="section === 'questions'">
                <div v-for="(block, index) in responseMapping" :key="block.title" class="mb-6">
                    <div class="border border-gray-200 rounded-lg shadow-md">
                        <div class="flex items-center justify-between bg-gray-100 p-4 rounded-t-lg">
                            <div class="flex items-center gap-2">
                                <span
                                    class="px-4 py-2 border border-gray-300 min-w-[100px] text-xs font-semibold rounded-lg">
                                    {{ typeLabel[block.type] }}
                                </span>
                                <h2 class="text-sm ml-3 font-semibold">{{ index + 1 }}. {{ block.title }}</h2>
                            </div>
                            <div class="flex items-center gap-1 text-blue-600 font-medium"
                                :class="{ 'hover:underline cursor-pointer': block.numberOfConversations > 0 }"
                                @click="block.numberOfConversations > 0 && openModal(block.conversations)">
                                <HeroIconChatBubbleLeftEllipsisOutline class="w-5 h-5" />
                                <span>{{ block.numberOfConversations }} Conversation{{ block.numberOfConversations == 1 ? '' : 's'
                                    }}</span>
                            </div>
                        </div>
                        <div class="flex">
                            <!-- CSAT Score Section -->
                            <div v-if="block.csatScore !== null"
                                class="flex flex-col items-center justify-center px-10 ml-10">
                                <span class="text-lg font-medium text-gray-500">
                                    CSAT Score
                                </span>
                                <span class="text-6xl font-extrabold text-gray-900">
                                    {{ block.csatScore.toFixed(0) }}%
                                </span>
                            </div>

                            <!-- Chart Section -->
                            <div v-if="block.chartOptions && block.type != 'explore'" class="px-10 py-4 w-full">
                                <apexchart v-if="block.hasResponses" :options="block.chartOptions"
                                    :series="block.series" type="bar" :height="block.chartHeight" />
                                <div v-else class="px-10 py-4 w-full text-center text-gray-500">
                                    No responses for this block
                                </div>
                            </div>
                            <div v-if="block.type == 'explore' && !block.hasResponses"
                                class="px-10 py-8 w-full text-center text-gray-500">
                                No responses for this block
                            </div>

                        </div>
                    </div>

                    <!-- Modal -->
                    <div v-if="isModalOpen"
                        class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-30 backdrop-blur-sm transition-opacity duration-300">
                        <div
                            class="bg-white rounded-2xl shadow-2xl w-11/12 md:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto p-6 relative">
                            <!-- Close Icon -->
                            <button class="absolute top-4 right-4 text-gray-500 hover:text-gray-800 transition-colors"
                                @click="closeModal">
                                <HeroIconXMark class="w-6 h-6" />
                            </button>
                            <!-- Conversations -->
                            <div v-for="(conversation, conversationIndex) in modalConversations"
                                :key="conversationIndex" class="mb-6 border border-gray-200 rounded-lg mt-5">
                                <div class="flex items-center justify-between bg-gray-100 p-4 rounded-t-lg">
                                    <div class="flex items-center">
                                        <div class="flex items-center justify-center h-6 w-6 rounded-full bg-gray-300">
                                            <UserIcon class="h-5 w-5 text-gray-600" />
                                        </div>
                                        <h2 class="text-sm ml-3">Anonymous</h2>
                                    </div>
                                    <span class="text-sm">
                                        {{ new Date(conversation[0].sent_at).toLocaleDateString("en-US", {
                                            month: "short", day:
                                                "2-digit", year: "numeric"
                                        }) }}
                                    </span>
                                </div>
                                <div v-for="(message, messageIndex) in conversation" :key="messageIndex"
                                    class="px-10 py-4">
                                    <div :class="{
                                        'flex justify-start space-x-2': message.author === 'user',
                                        'flex justify-end space-x-2': message.author === 'system'
                                    }">
                                        <div v-if="message.author === 'user'"
                                            class="flex items-center justify-center h-7 w-7 rounded-full bg-gray-300">
                                            <UserIcon class="h-6 w-6" />
                                        </div>

                                        <div :class="{
                                            'bg-indigo-500 text-white text-left px-5 py-4 rounded-lg rounded-tl-none max-w-sm': message.author === 'user',
                                            'bg-gray-100 text-gray-800 text-left px-5 py-4 rounded-lg rounded-tr-none max-w-sm': message.author === 'system'
                                        }">
                                            <p class="text-sm">{{ message.value }}</p>
                                        </div>

                                        <div v-if="message.author === 'system'" class="text-gray-600 text-3xl">
                                            🤖
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Section Responses -->
            <div v-if="section == 'responses'">
                <div v-for="(conversation, conversationIndex) in compiledConversations" :key="conversationIndex"
                    class="mb-6 border border-gray-200 rounded-lg mt-5">
                    <div class="flex items-center justify-between bg-gray-100 p-4 rounded-t-lg">
                        <div class="flex items-center">
                            <div class="flex items-center justify-center h-6 w-6 rounded-full bg-gray-300">
                                <UserIcon class="h-5 w-5 text-gray-600" />
                            </div>
                            <h2 class="text-sm ml-3">Anonymous</h2>
                        </div>
                        <span class="text-sm">
                            {{ new Date(conversation.conversations[0].sent_at).toLocaleDateString("en-US", {
                                month: "short", day:
                                    "2-digit", year: "numeric"
                            }) }}
                        </span>
                    </div>
                    <div class="flex flex-col space-y-4 px-10 py-4 items-center">
                        <div v-for="(message, messageIndex) in conversation.conversations" :key="messageIndex"
                            class="max-w-4xl w-full">
                            <div :class="{
                                'flex justify-start space-x-2': message.author === 'user',
                                'flex justify-end space-x-2': message.author === 'system'
                            }">
                                <div v-if="message.author === 'user'"
                                    class="flex items-center justify-center h-7 w-7 rounded-full bg-gray-300">
                                    <UserIcon class="h-6 w-6" />
                                </div>

                                <div :class="{
                                    'bg-indigo-500 text-white text-left px-5 py-4 rounded-lg rounded-tl-none max-w-sm': message.author === 'user',
                                    'bg-gray-100 text-gray-800 text-left px-5 py-4 rounded-lg rounded-tr-none max-w-sm': message.author === 'system'
                                }">
                                    <p class="text-sm">{{ message.value }}</p>
                                </div>

                                <div v-if="message.author === 'system'" class="text-gray-600 text-3xl">
                                    🤖
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApexCharts from "vue3-apexcharts";
import supabase from "../../../src/supabase";
import { XMarkIcon as HeroIconXMark, TrashIcon } from '@heroicons/vue/24/outline';
import { UserIcon } from '@heroicons/vue/20/solid';
import { ChatBubbleLeftEllipsisIcon as HeroIconChatBubbleLeftEllipsisOutline } from "@heroicons/vue/24/outline";

export default {
    name: "ViewResponse",
    components: {
        apexchart: ApexCharts,
        HeroIconChatBubbleLeftEllipsisOutline,
        HeroIconXMark,
        UserIcon,
        TrashIcon
    },
    props: {
        tenantId: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            filter: "alltime",
            showAppliedFilters: false,
            customStartDate: '',
            customEndDate: '',
            langLabelValMap: {},
            filters: {
                applied: [],
                matchOption: "all",
                options: [],
            },
            filterOptions: [
                { label: 'Last 7 Days', value: 'last7days' },
                { label: 'Last 30 Days', value: 'last30days' },
                { label: 'This Week', value: 'thisweek' },
                { label: 'This Month', value: 'thismonth' },
                { label: 'Custom', value: 'custom' },
                { label: 'All Time', value: 'alltime' },
            ],
            isModalOpen: false,
            modalConversations: [],
            isLoading: true,
            section: 'questions',
            survey_id: this.$route.params.survey_id,
            responseMapping: [],
            typeLabel: {
                message: "Message",
                rate: "Rate",
                csat: "CSAT",
                select: "Select",
                multi_choice: "Multi-Choice",
                explore: "Explore",
            }
        };
    },
    methods: {
        async fetchSurveyResponses() {
            try {
                this.isLoading = true;

                const { data: blocks, error: blocksError } = await supabase
                    .from("survey_blocks")
                    .select("id, title, type, position, rating_min, rating_max, choices")
                    .neq("type", "message")
                    .eq("survey_id", this.survey_id);
                if (blocksError) throw blocksError;

                const blockIds = blocks.map((block) => block.id);

                let responsesQuery = supabase
                    .from("responses")
                    .select("id, created_at, participant")
                    .eq("is_preview", false)
                    .eq("survey_id", this.survey_id);

                // Apply time-based filters
                const today = new Date();
                if (this.filter === "last7days") {
                    const sevenDaysAgo = new Date();
                    sevenDaysAgo.setDate(today.getDate() - 7);
                    responsesQuery = responsesQuery.gte("created_at", sevenDaysAgo.toISOString());
                } else if (this.filter === "last30days") {
                    const thirtyDaysAgo = new Date();
                    thirtyDaysAgo.setDate(today.getDate() - 30);
                    responsesQuery = responsesQuery.gte("created_at", thirtyDaysAgo.toISOString());
                } else if (this.filter === "thisweek") {
                    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                    responsesQuery = responsesQuery.gte("created_at", startOfWeek.toISOString());
                } else if (this.filter === "thismonth") {
                    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                    responsesQuery = responsesQuery.gte("created_at", startOfMonth.toISOString());
                } else if (this.filter === "custom") {
                    if (this.customStartDate && this.customEndDate) {
                        responsesQuery = responsesQuery
                            .gte("created_at", new Date(this.customStartDate).toISOString())
                            .lte("created_at", new Date(this.customEndDate).toISOString());
                    } else {
                        console.warn("Custom date range not fully selected.");
                        return;
                    }
                }

                for (const filter of this.filters.applied) {
                    if (filter.type === "language" || filter.type === "device" || filter.type === "user_attribute") {
                        const field = filter.type === "user_attribute" ? filter.option.value : filter.type;
                        const operator = this.mapOperatorToSupabase(filter.operator);
                        let criteria = filter.type == 'language' ? this.langLabelValMap[filter.criteria] : filter.criteria;

                        // Adjust criteria for specific operators
                        if (filter.operator === 'endsWith') {
                            criteria = `%${criteria}`;
                        } else if (filter.operator === 'startsWith') {
                            criteria = `${criteria}%`;
                        }

                        if (operator === "is" || operator === "not") {
                            // Handle boolean comparison explicitly
                            const isBoolean = criteria === "true";
                            responsesQuery = responsesQuery.filter(
                                `participant->>${field}`,
                                operator === "is" ? "eq" : "neq",
                                isBoolean
                            );
                        } else {
                            // For other operators, compare directly
                            responsesQuery = responsesQuery.filter(
                                `participant->>${field}`,
                                operator,
                                criteria
                            );
                        }
                    }
                }

                const { data: responses, error: responsesError } = await responsesQuery;
                if (responsesError) throw responsesError;

                const responseIds = responses.map((response) => response.id);

                const { data: responseMessages, error: messagesError } = await supabase
                    .rpc("fetch_response_messages", { block_ids: blockIds, response_ids: responseIds });
                if (messagesError) throw messagesError;

                const transformedData = blocks.sort((a, b) => a.position - b.position).map((block) => {
                    const appliedFilter = this.filters.applied.find(
                        (filter) => filter.type === "response_to" && filter.option.value === block.title
                    );

                    let blockResponses = responseMessages.filter((message) => message.block_id === block.id);

                    if (appliedFilter) {
                        const operator = this.mapOperatorToSupabase(appliedFilter.operator);
                        let criteria = appliedFilter.criteria;

                        // Adjust criteria for specific operators
                        if (appliedFilter.operator === 'endsWith') {
                            criteria = `%${criteria}`;
                        } else if (appliedFilter.operator === 'startsWith') {
                            criteria = `${criteria}%`;
                        }

                        blockResponses = blockResponses.filter((message) => {
                            const value = block.type === "rate" || block.type === "csat" ? parseInt(message.value, 10) : message.value;
                            if (operator === "ilike" || operator === "like") {
                                return value.toString().toLowerCase().includes(criteria.toLowerCase());
                            } else if (operator === "eq") {
                                return value === criteria;
                            } else if (operator === "neq") {
                                return value !== criteria;
                            } else if (operator === "gt") {
                                return parseFloat(value) > parseFloat(criteria);
                            } else if (operator === "lt") {
                                return parseFloat(value) < parseFloat(criteria);
                            } else if (operator === "gte") {
                                return parseFloat(value) >= parseFloat(criteria);
                            } else if (operator === "lte") {
                                return parseFloat(value) <= parseFloat(criteria);
                            }
                            return false;
                        });
                    }

                    const flatResponses = blockResponses.flatMap((message) => {
                        const response = responses.find((resp) => resp.id === message.response_id);
                        if (block.type === "multi_choice" && message.value.includes(",")) {
                            return message.value.split(",").map((value) => ({
                                value: value.trim(),
                                date: response?.created_at,
                                conversation: message.conversation,
                            }));
                        }
                        return {
                            value: message.value,
                            date: response?.created_at,
                            conversation: message.conversation,
                        };
                    });

                    const seriesData = flatResponses.reduce((acc, response) => {
                        acc[response.value] = (acc[response.value] || 0) + 1;
                        return acc;
                    }, {});

                    const numberOfBars = Object.keys(seriesData).length;
                    const chartHeight = numberOfBars * 50 + 50;

                    const isVerticalChart = block.type === "select" || block.type === "multi_select";

                    let csatScore = null;
                    if (block.type === "csat" && block.rating_min !== undefined && block.rating_max !== undefined) {
                        const csatValues = blockResponses.map((response) => {
                            const value = parseFloat(response.value);
                            if (!isNaN(value)) {
                                return ((value - block.rating_min) / (block.rating_max - block.rating_min)) * 100;
                            }
                            return null;
                        }).filter(value => value !== null);

                        csatScore = csatValues.length
                            ? csatValues.reduce((sum, score) => sum + score, 0) / csatValues.length
                            : null;
                    }

                    let convo = Array.from(new Set(blockResponses.map(response => response.conversation)))
                        .filter(conversation => conversation !== null);
                        
                    return {
                        title: block.title,
                        position: block.position,
                        type: block.type,
                        chartHeight: chartHeight,
                        min_value: block.rating_min,
                        max_value: block.rating_max,
                        choices: block.choices,
                        numberOfConversations: convo.length,
                        hasResponses: Object.keys(seriesData).length > 0,
                        csatScore: csatScore,
                        series: [{
                            name: "Responses",
                            data: Object.values(seriesData)
                        }],
                        chartOptions: {
                            chart: {
                                type: "bar",
                                height: chartHeight,
                                stacked: false,
                                toolbar: {
                                    show: false
                                }
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: !isVerticalChart,
                                    barHeight: "80%",
                                    borderRadius: 5,
                                    borderRadiusApplication: "end",
                                    distributed: true
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            legend: {
                                show: false
                            },
                            grid: {
                                show: false
                            },
                            tooltip: {
                                enabled: true
                            },
                            xaxis: {
                                categories: Object.keys(seriesData),
                                labels: {
                                    show: isVerticalChart
                                },
                                axisBorder: {
                                    show: isVerticalChart
                                }
                            },
                            yaxis: {
                                labels: {
                                    show: !isVerticalChart
                                },
                                axisBorder: {
                                    show: !isVerticalChart
                                }
                            }
                        },
                        conversations: convo
                    };
                });
                this.responseMapping = transformedData;
            } catch (error) {
                console.error("Error fetching survey responses:", error);
                throw error;
            } finally {
                this.isLoading = false;
            }
        },
        addFilter() {
            this.filters.applied.push({ type: "Response to", value: "", operator: "=", criteria: "" });
        },
        async clearAllFilters() {
            this.showAppliedFilters = false;
            this.filters.applied = [];
            await this.fetchSurveyResponses();
        },
        setFilter() {
            if (this.filter === 'custom') {
                if (!this.customStartDate || !this.customEndDate) {
                    return;
                }
            }
            this.fetchSurveyResponses();
        },
        setSection(section) {
            this.section = section;
        },
        openModal(conversations) {
            this.modalConversations = conversations;
            this.isModalOpen = true;
        },
        removeFilter(index) {
            this.filters.applied.splice(index, 1);
        },
        closeModal() {
            this.isModalOpen = false;
            this.modalConversations = [];
        },
        async fetchTenantUserAttributes() {
            try {
                let query = supabase
                    .from("tenant_user_attributes")
                    .select("id, attribute_name, data_type")
                    .order("created_at", { ascending: false });

                if (this.tenantId) {
                    query.eq("tenant_id", this.tenantId);
                }

                const { data, error } = await query;
                if (error) {
                    throw new Error(`Error fetching attributes: ${error.message}`);
                }

                return data;
            } catch (err) {
                console.error("Error fetching tenant user attributes:", err);
                return [];
            }
        },
        mapOperatorToSupabase(operator) {
            const operatorMap = {
                '>=': 'gte',
                '<=': 'lte',
                '=': 'eq',
                '!=': 'neq',
                '>': 'gt',
                '<': 'lt',
                'contains': 'ilike',
                'not contains': 'not ilike',
                'selected': 'eq',
                'not selected': 'neq',
                'is': 'is',
                'is not': 'not',
                'is greater than': 'gt',
                'is less than': 'lt',
                'equals': 'eq',
                'not equals': 'neq',
                'includes': 'ilike',
                'not includes': 'not ilike',
                'endsWith': 'like',
                'startsWith': 'like',
            };
            return operatorMap[operator] || operator;
        },
        async fetchLanguages() {
            try {
                const { data, error } = await supabase
                    .from("languages")
                    .select("label, value");

                if (error) {
                    throw new Error(`Error fetching languages: ${error.message}`);
                }

                this.langLabelValMap = data.reduce((acc, item) => {
                    acc[item.label] = item.value;
                    return acc;
                }, {});

                return data;
            } catch (err) {
                console.error("Error fetching languages:", err);
                return [];
            }
        },
        async getFilterSelectionOptions() {
            try {
                const [userAttributes, languageData] = await Promise.all([
                    this.fetchTenantUserAttributes(),
                    this.fetchLanguages()
                ]);

                let operator_type_map = {
                    'csat': ['>=', '<=', '=', '!=', '>', '<'],
                    'rate': ['>=', '<=', '=', '!=', '>', '<'],
                    'select': ['selected', 'not selected'],
                    'multi_choice': ["includes", "not includes"],
                    'explore': ["equals", "not equals", "contains", "not contains"],
                    'number': ['=', '>', '<', '>=', '<=', '!='],
                    'text': ['contains', 'equals', 'endsWith', 'startsWith'],
                    'bool': ['is', 'is not'],
                    'date': ['is greater than', 'is less than', 'equals', 'not equals'],
                    'datetime-local': ['is greater than', 'is less than'],
                };

                let criteria_map = {
                    'csat': res => Array.from({ length: res.max_value - res.min_value + 1 }, (_, i) => res.min_value + i),
                    'rate': res => Array.from({ length: res.max_value - res.min_value + 1 }, (_, i) => res.min_value + i),
                    'select': res => res.choices,
                    'multi_choice': res => res.choices,
                    'explore': () => 'text',
                    'number': () => 'number',
                    'text': () => 'text',
                    'bool': () => ['true', 'false'],
                    'date': () => 'date',
                    'datetime-local': () => 'datetime-local',
                    'device': () => ['desktop', 'mobile'],
                    'language': res => [res.label],
                };

                this.filters.options = [
                    {
                        label: 'Response to',
                        value: 'response_to',
                        options: this.responseMapping.map(res => ({
                            label: res.title,
                            value: res.title,
                            type: res.type,
                            max_value: res.max_value,
                            min_value: res.min_value,
                            operators: operator_type_map[res.type],
                            criteria: criteria_map[res.type](res),
                        })),
                    },
                    {
                        label: 'User Attribute',
                        value: 'user_attribute',
                        options: userAttributes.map(attr => ({
                            label: attr.attribute_name,
                            value: attr.attribute_name,
                            type: attr.data_type,
                            operators: operator_type_map[attr.data_type],
                            criteria: criteria_map[attr.data_type]({}),
                        })),
                    },
                    {
                        label: 'Device',
                        value: 'device',
                        options: [{
                            label: 'Device',
                            value: 'device',
                            type: 'device',
                            operators: [],
                            criteria: criteria_map['device'](),
                        }],
                    },
                    {
                        label: 'Language',
                        value: 'language',
                        options: languageData.map(lang => ({
                            label: lang.label,
                            value: lang.value,
                            operators: [],
                            criteria: criteria_map['language'](lang),
                        })),
                    },
                ];

            } catch (err) {
                console.error("Error fetching filter selection options:", err);
                return {};
            }
        },
        async applyFilters() {
            this.showAppliedFilters = false;
            await this.fetchSurveyResponses();
        }
    },
    async mounted() {
        await this.fetchSurveyResponses();
        await this.getFilterSelectionOptions()
    }
};
</script>

<style scoped>
button {
    outline: none;
    cursor: pointer;
}

.chart-container {
    max-width: 100%;
    margin: 0 auto;
}
</style>