<template>
  <div class="flex flex-col w-full">
    <div class="px-6 pt-5 border-b border-gray-200">
      <div v-if="isLoading">
        <!-- Skeleton -->
        <div class="flex items-center justify-center bg-gray-100">
          <div class="flex items-center justify-between w-full p-4 bg-white rounded-lg">
            <div class="h-5 w-32 bg-gray-200 animate-pulse rounded"></div>
            <div class="flex space-x-4">
              <div class="h-9 w-20 bg-gray-200 animate-pulse rounded"></div>
              <div class="h-9 w-20 bg-gray-200 animate-pulse rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-between mb-5">
        <div class="flex flex-col">
          <h1 class="text-2xl text-left font-semibold">{{ survey_title }}</h1>
          <span class="text-left text-sm text-gray-500" v-if="status !== 'draft'">
            {{ responseCount }}{{ responseLimit ? ` / ${responseLimit}` : '' }} responses |
            {{ responseCount && viewResponseCount
              ? ((responseCount / viewResponseCount) * 100).toFixed(2)
            : 0
            }}% response rate
          </span>
        </div>
        <div class="flex items-center">
          <button type="button" @click="previewSurvey" v-if="status === 'draft'"
            class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Preview
          </button>
          <button type="button" @click="updateSurveyStatus('in_progress')" :disabled="isUpdating"
            v-if="status === 'draft'"
            class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <span v-if="isUpdating"
              class="animate-spin inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full"></span>
            <span v-else>Publish</span>
          </button>
          <button type="button" @click="editSurvey" :disabled="isUpdating" v-if="status !== 'draft'"
            class="rounded-md bg-white px-7 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-4">
            <span v-if="isUpdating"
              class="animate-spin inline-block w-4 h-4 border-2 border-gray-400 rounded-full"></span>
            <span v-else>Edit</span>
          </button>
          <select v-if="status != 'draft'" id="status" v-model="status" @change="updateSurveyStatus(status)"
            class="rounded-md bg-white px-5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-blue-300">
            <option value="in_progress">In-Progress</option>
            <option value="pause">Pause</option>
            <option value="archive">Archive</option>
          </select>
        </div>
      </div>

      <!-- Edit popup -->
      <div v-if="showSurveyEditModal" class="relative z-10" aria-labelledby="modal-title" role="dialog"
        aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Risk of Data Loss!</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Modifying this survey may result in irrversible data loss. Proceed
                      with caution.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" @click="updateSurveyStatus('draft')"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto">
                  I accept this risk
                </button>
                <button type="button" @click="showSurveyEditModal = false"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- old tab bar -->
      <div class="flex items-center space-x-7 mt-5">
        <div v-if="status == 'draft'" class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="section === 'Overview' ? 'text-indigo-600 border-indigo-600 text-sm font-medium' : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          @click="section = 'Overview'">
          <span>Overview</span>
        </div>
        <div v-if="status == 'draft'" class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="section === 'Questions' ? 'text-indigo-600 border-indigo-600 text-sm font-medium' : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          @click="section = 'Questions'">
          <span>Questions</span>
        </div>
        <div v-if="status == 'draft'" class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="section === 'Configure' ? 'text-indigo-600 border-indigo-600 text-sm font-medium' : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          @click="section = 'Configure'">
          <span>Targeting</span>
        </div>
        <div v-if="status != 'draft'" class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="section === 'Responses' ? 'text-indigo-600 border-indigo-600 text-sm font-medium' : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          @click="section = 'Responses'">
          <span>Responses</span>
        </div>
        <div v-if="status != 'draft'" class="flex items-center space-x-1 cursor-pointer border-b-2 pb-4 px-2"
          :class="section === 'Share' ? 'text-indigo-600 border-indigo-600 text-sm font-medium' : 'text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'"
          @click="section = 'Share'">
          <span>Share</span>
        </div>
      </div>
    </div>
    <OverviewSection v-if="section === 'Overview'" />
    <CreateSurvey v-if="section === 'Questions'" />
    <ConfigureSurvey v-if="section === 'Configure'" />
    <ShareSurvey v-if="section === 'Share'" />
    <ViewResponse v-if="section === 'Responses'" />
  </div>
</template>

<script>
import OverviewSection from "./OverviewSection.vue";
import CreateSurvey from "./CreateSurvey.vue";
import ConfigureSurvey from "./ConfigureSurvey.vue";
import ShareSurvey from './ShareSurvey.vue';
import ViewResponse from './ViewResponse.vue';
import supabase from "../../../src/supabase";
import { useAuthStore } from '@/stores/auth'

export default {
  components: {
    OverviewSection,
    CreateSurvey,
    ConfigureSurvey,
    ShareSurvey,
    ViewResponse
  },
  data() {
    return {
      currentUserId: null,
      userRole: null,
      tenantId: null,
      status: "",
      section: "",
      survey_title: "",
      isLoading: false,
      isUpdating: false,
      responseLimit: 0,
      responseCount: 0,
      viewResponseCount: 0,
      showSurveyEditModal: false,
      survey_id: this.$route.params.survey_id,
    };
  },
  mounted() {
    const authStore = useAuthStore();
    this.currentUserId = authStore.user.id;
    this.userRole = authStore.role;
    this.tenantId = authStore.tenantId;
    this.fetchSurvey();
  },
  methods: {
    previewSurvey() {
      window.open(`/survey/${this.survey_id}?isPreview=true`, '_blank');
    },
    async fetchSurvey() {
      try {
        this.isLoading = true;

        // Fetch survey details
        const { data: surveyData, error: surveyError } = await supabase
          .from('tenant_surveys')
          .select("id, status, title, response_limit, tenant_id, tenants(name), title, responses:responses(id, response_messages:response_messages(id))")
          .eq('id', this.survey_id)
          .single();

        if (surveyError) {
          console.error('Error fetching tenant_surveys:', surveyError);
        } else {
          this.survey_title = surveyData.title;
          this.status = surveyData.status;
          this.responseLimit = surveyData.response_limit;
          if (this.status === 'draft') {
            this.section = 'Overview';
          } else {
            this.section = 'Responses';
          }
        }

        this.responseCount = surveyData.responses
          ? surveyData.responses.filter(
            (response) =>
              response.response_messages &&
              response.response_messages.length > 0
          ).length
          : 0;

        this.viewResponseCount = surveyData.responses ? surveyData.responses.length : 0;

      } catch (err) {
        console.error('Unexpected error:', err);
      } finally {
        this.isLoading = false;
      }
    },
    editSurvey() {
      if (this.responseCount > 0) {
        this.showSurveyEditModal = true
      } else {
        this.updateSurveyStatus('draft');
      }
    },
    async updateSurveyStatus(newStatus) {
      this.showSurveyEditModal = false;
      try {
        this.isUpdating = true;

        const { error } = await supabase
          .from('tenant_surveys')
          .update({ status: newStatus })
          .eq('id', this.survey_id);

        if (error) {
          console.error('Error updating status:', error);
        } else {
          this.status = newStatus;
          if (this.status == 'draft') {
            this.section = 'Overview';
          } else {
            this.section = 'Responses';
          }
        }
      } catch (err) {
        console.error('Unexpected error:', err);
      } finally {
        this.isUpdating = false;
      }
    },
  },
};
</script>

<style scoped></style>
