<template>
  <div class="p-6 max-w-4xl mx-auto w-full">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">User Attributes</h2>
      <button type="button" class="btn-primary-sondar" @click="showPopup = true">
        New Attribute
      </button>
    </div>

    <div v-if="isLoading">
      <div class="animate-pulse space-y-4 p-4">
        <div class="flex items-center justify-between rounded bg-gray-100 p-4 shadow">
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div class="flex items-center justify-between rounded bg-gray-100 p-4 shadow">
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div class="flex items-center justify-between rounded bg-gray-100 p-4 shadow">
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div class="flex items-center justify-between rounded bg-gray-100 p-4 shadow">
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
            <div class="h-3 w-1/3 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="attributes.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">
          There are no custom attributes. Create one now!
        </p>
      </div>

      <div v-else class="overflow-hidden">
        <ul role="list" class="divide-y divide-gray-200 border-t border-b border-gray-200">
          <li v-for="(attribute, index) in paginatedAttributes" :key="index" class="py-4">
            <div class="flex justify-between items-center">
              <div class="min-w-0 flex-1 text-start">
                <h3 class="text-sm font-medium text-gray-900 truncate" :title="attribute.name">
                  {{ attribute.name }}
                </h3>
                <p class="mt 2 text-sm text-gray-500">
                  Created: {{ formatDate(attribute.createdAt) }}
                </p>
              </div>

              <div class="flex items-center gap-3">
                <span
                  class="inline-flex items-center justify-center rounded-md bg-gray-50 w-24 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-gray-500/10 ring-inset">{{
                    attribute.dataTypeDisplay[0].toUpperCase() + attribute.dataTypeDisplay.slice(1) }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="!isLoading && attributes.length > itemsPerPage" class="flex justify-between items-center mt-4">
      <button type="button" @click="previousPage" :disabled="currentPage === 1"
        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
        Previous
      </button>
      <span class="text-sm font-medium text-gray-700">
        Page {{ currentPage }} of {{ totalPages }}
      </span>
      <button type="button" @click="nextPage" :disabled="currentPage === totalPages"
        class="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50">
        Next
      </button>
    </div>

    <!-- Create a New Attribute Modal -->
    <div v-if="showPopup" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div>
              <div>
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                  Create a New Attribute
                </h3>
              </div>
            </div>

            <div class="mt-6 mb-16">
              <div class="mb-4">
                <label for="attribute-name" class="block text-sm font-medium leading-6 text-gray-900">Attribute
                  Name</label>
                <input id="attribute-name" v-model="newAttributeName" type="text" placeholder="e.g. planType"
                  class="input-sondar" />
                <p v-if="errorMessageName" class="mt-1 text-sm text-red-600">
                  {{ errorMessageName }}
                </p>
              </div>

              <div class="mb-4">
                <label for="attribute-data-type" class="block text-sm font-medium leading-6 text-gray-900">Data
                  Type</label>
                <select id="attribute-data-type" v-model="newAttributeDataType" class="select-sondar">
                  <option value="" disabled>Select data type</option>
                  <option value="number">Number</option>
                  <option value="text">Text</option>
                  <option value="bool">Boolean</option>
                  <option value="datetime-local">Date & Time</option>
                  <option value="date">Date</option>
                </select>
                <p v-if="errorMessageDataType" class="mt-1 text-sm text-red-600">
                  {{ errorMessageDataType }}
                </p>
              </div>

              <div class="mb-4">
                <label for="attribute-description"
                  class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                <input id="attribute-description" v-model="newAttributeDescription" type="text"
                  placeholder="Enter attribute description (optional)" class="input-sondar" />
              </div>

              <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                {{ errorMessage }}
              </p>
            </div>

            <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button type="button" @click="closeModal()" class="btn-secondary-sondar">
                Cancel
              </button>
              <button type="button" @click="createAttribute" class="btn-primary-sondar">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from "../../../src/supabase";
import { format } from "date-fns";
import { useAuthStore } from '@/stores/auth'

export default {
  data() {
    return {
      currentUserId: null,
      userRole: null,
      tenantId: null,
      attributes: [],
      currentPage: 1,
      itemsPerPage: 10,
      isLoading: true,
      showPopup: false,
      newAttributeName: "",
      newAttributeDataType: "",
      newAttributeDescription: "",
      errorMessageName: "",
      errorMessageDataType: "",
      errorMessage: "",
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.attributes.length / this.itemsPerPage);
    },
    paginatedAttributes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.attributes.slice(start, start + this.itemsPerPage);
    },
  },
  methods: {
    closeModal() {
      this.showPopup = false;
      this.newAttributeName = "";
      this.newAttributeDataType = "";
      this.newAttributeDescription = "";
      this.errorMessageName = "";
      this.errorMessageDataType = "";
      this.errorMessage = "";
    },
    async fetchAttributes() {
      this.isLoading = true;
      try {
        let query = supabase
          .from("tenant_user_attributes")
          .select("id, attribute_name, data_type, created_at, description")
          .order("created_at", { ascending: false });

        if (this.tenantId) {
          query.eq("tenant_id", this.tenantId);
        }

        const { data, error } = await query;

        if (error) {
          console.error("Error fetching attributes:", error);
          return;
        }

        this.attributes = data.map((attribute) => ({
          id: attribute.id,
          name: attribute.attribute_name,
          dataType: attribute.data_type,
          dataTypeDisplay:
            attribute.data_type === "datetime-local"
              ? "date & time"
              : attribute.data_type,
          createdAt: attribute.created_at,
          description: attribute.description,
          showMenu: false,
        }));
      } catch (err) {
        console.error("Unexpected error:", err);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      return format(new Date(date), "MMM do, yyyy");
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async createAttribute() {
      let isValid = true;

      if (!this.newAttributeName.trim() || this.newAttributeName.includes(" ")) {
        this.errorMessageName = "Attribute name is required. Must not contain any spaces.";
        isValid = false;
      } else {
        this.errorMessageName = "";
      }

      if (!this.newAttributeDataType) {
        this.errorMessageDataType = "Data type is required.";
        isValid = false;
      } else {
        this.errorMessageDataType = "";
      }

      if (!isValid) return;

      try {
        const { error } = await supabase.from("tenant_user_attributes").insert([
          {
            attribute_name: this.newAttributeName.trim(),
            data_type: this.newAttributeDataType,
            description: this.newAttributeDescription.trim() || null,
            tenant_id: this.tenantId,
          },
        ]);

        if (error) {
          console.error("Error creating attribute:", error);
          this.errorMessage = "Failed to create attribute. Try again later.";
          return;
        }

        this.newAttributeName = "";
        this.newAttributeDataType = "";
        this.newAttributeDescription = "";
        this.errorMessageName = "";
        this.errorMessageDataType = "";
        this.errorMessage = "";
        this.showPopup = false;
        this.fetchAttributes();
      } catch (err) {
        console.error("Unexpected error:", err);
        this.errorMessage = "An unexpected error occurred.";
      }
    },
    editAttribute(id) {
      console.log(id);
    },
    async deleteAttribute(id) {
      console.log(id);
    },
  },
  async mounted() {
    const authStore = useAuthStore();
    this.currentUserId = authStore.user.id;
    this.userRole = authStore.role;
    this.tenantId = authStore.tenantId;
    await this.fetchAttributes();
  },
};
</script>
