<template>
  <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
      <h2 class="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">
        Set your password
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <form v-if="!isPasswordUpdated" @submit.prevent="handleSetPassword" class="space-y-6">
          <div>
            <label for="password" class="block text-left text-sm/6 font-medium text-gray-900">New Password</label>
            <div class="mt-2">
              <input v-model="password" type="password" name="password" id="password" required @input="validatePassword"
                class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" />
              <p v-if="passwordError" class="mt-1 text-left text-sm text-red-600">
                {{ passwordError }}
              </p>
              <ul v-if="password" class="mt-3 space-y-1">
                <li v-for="(condition, key) in passwordChecklist" :key="key" class="flex items-center">
                  <span :class="condition.met ? 'text-green-600' : 'text-gray-500'" class="mr-2">
                    <svg v-if="condition.met" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </span>
                  <span class="text-sm" :class="condition.met ? 'text-green-600' : 'text-gray-500'">
                    {{ condition.message }}
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <label for="confirm-password" class="block text-left text-sm/6 font-medium text-gray-900">Confirm
              Password</label>
            <div class="mt-2">
              <input v-model="confirmPassword" type="password" name="confirm-password" id="confirm-password" required
                @input="validateConfirmPassword"
                class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" />
              <p v-if="confirmPasswordError" class="mt-1 text-left text-sm text-red-600">
                {{ confirmPasswordError }}
              </p>
            </div>
          </div>

          <div>
            <button :disabled="!isPasswordValid" type="submit"
              class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed">
              Set Password
            </button>
          </div>

          <p v-if="formError" class="mt-4 text-sm text-red-600">
            {{ formError }}
          </p>
        </form>
        <div v-else class="text-center">
          <h3 class="text-lg font-semibold text-gray-900">Password Updated</h3>
          <p class="mt-4 text-sm text-gray-700">Your password has been successfully updated. You will be redirected to the login page shortly.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from "../../../src/supabase";
import { useAuthStore } from "../../stores/auth";

export default {
  name: "SetPassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordError: "",
      confirmPasswordError: "",
      formError: "",
      isPasswordUpdated: false,
      isPasswordValid: false,
      passwordChecklist: {
        length: { message: "At least 8 characters", met: false },
        uppercase: { message: "At least one uppercase letter", met: false },
        number: { message: "At least one number", met: false },
        special: { message: "At least one special character", met: false },
      },
    };
  },

  methods: {
    validatePassword() {
      const length = this.password.length >= 8;
      const uppercase = /[A-Z]/.test(this.password);
      const number = /\d/.test(this.password);
      const special = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);

      this.passwordChecklist.length.met = length;
      this.passwordChecklist.uppercase.met = uppercase;
      this.passwordChecklist.number.met = number;
      this.passwordChecklist.special.met = special;

      this.isPasswordValid = length && uppercase && number && special;
      this.passwordError = this.isPasswordValid ? "" : "Password does not meet requirements";
    },
    validateConfirmPassword() {
      this.confirmPasswordError =
        this.password === this.confirmPassword ? "" : "Passwords do not match";
    },
    async handleSetPassword() {
      this.passwordError = "";
      this.confirmPasswordError = "";
      this.formError = "";

      if (!this.password) {
        this.passwordError = "Password is required";
        return;
      }
      if (!this.confirmPassword) {
        this.confirmPasswordError = "Please confirm your password";
        return;
      }
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordError = "Passwords do not match";
        return;
      }

      try {
        const { error } = await supabase.auth.updateUser({
          password: this.password,
        });

        const authStore = useAuthStore();
        await authStore.signOut();

        if (error) {
          this.formError = error.message;
          return;
        }

        this.isPasswordUpdated = true;
        setTimeout(() => {
          this.$router.push("/login");
        }, 3000);
      } catch (err) {
        this.formError =
          "An unexpected error occurred. Please try again later.";
      }
    },
  },
};
</script>
