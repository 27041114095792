<template>
  <div class="relative h-screen">
    <!-- Email confirmation modal -->
    <div v-if="showConfirmationModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <!-- Close button -->
            <div class="absolute right-0 top-0 pr-4 pt-4">
              <button 
                type="button" 
                @click="showConfirmationModal = false"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span class="sr-only">Close</span>
                <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <div>
              <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Your account has been created!</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    We've sent you an email with a confirmation link. Please check your inbox and confirm your email address before signing in.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <router-link
                to="/login"
                class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Go to sign in
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid xl:grid-cols-2 h-full">
      <!-- Left side with form -->
      <div class="flex flex-col">
        <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
            <h2 class="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Create your account
            </h2>
          </div>

          <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form @submit.prevent="handleSignUp" class="space-y-6">
              <div>
                <label for="name" class="block text-sm font-medium leading-6 text-gray-900 text-left">Full name</label>
                <div class="mt-2">
                  <input 
                    v-model="name" 
                    type="text" 
                    name="name" 
                    id="name" 
                    autocomplete="name" 
                    required
                    placeholder="John Doe"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="nameError" class="mt-1 text-left text-sm text-red-600">{{ nameError }}</p>
                </div>
              </div>

              <div>
                <label for="email" class="block text-sm font-medium leading-6 text-gray-900 text-left">Email address</label>
                <div class="mt-2">
                  <input 
                    v-model="email" 
                    type="email" 
                    name="email" 
                    id="email" 
                    autocomplete="email" 
                    required
                    placeholder="john@example.com"
                    @input="validateEmail"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="emailError" class="mt-1 text-left text-sm text-red-600">{{ emailError }}</p>
                </div>
              </div>

              <div>
                <label for="organizationName" class="block text-sm font-medium leading-6 text-gray-900 text-left">Organization name</label>
                <div class="mt-2">
                  <input 
                    v-model="organizationName" 
                    type="text" 
                    name="organizationName" 
                    id="organizationName" 
                    required
                    placeholder="Acme Corporation"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="organizationError" class="mt-1 text-left text-sm text-red-600">{{ organizationError }}</p>
                </div>
              </div>

              <div>
                <label for="password" class="block text-sm font-medium leading-6 text-gray-900 text-left">Password</label>
                <div class="mt-2">
                  <input 
                    v-model="password" 
                    type="password" 
                    name="password" 
                    id="password" 
                    autocomplete="new-password"
                    required
                    placeholder="••••••••"
                    @input="validatePassword"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="passwordError" class="mt-1 text-left text-sm text-red-600">{{ passwordError }}</p>
                  <ul v-if="password" class="mt-3 space-y-1">
                    <li v-for="(condition, key) in passwordChecklist" :key="key" class="flex items-center">
                      <span :class="condition.met ? 'text-green-600' : 'text-gray-500'" class="mr-2">
                        <svg v-if="condition.met" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </span>
                      <span class="text-sm" :class="condition.met ? 'text-green-600' : 'text-gray-500'">
                        {{ condition.message }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <label for="confirmPassword" class="block text-sm font-medium leading-6 text-gray-900 text-left">
                  Confirm password
                </label>
                <div class="mt-2">
                  <input 
                    v-model="confirmPassword" 
                    type="password" 
                    name="confirmPassword" 
                    id="confirmPassword" 
                    required
                    placeholder="••••••••"
                    @input="validateConfirmPassword"
                    class="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <p v-if="confirmPasswordError" class="mt-1 text-left text-sm text-red-600">{{ confirmPasswordError }}</p>
                </div>
              </div>

              <div class="flex items-center">
                <div class="flex gap-3">
                  <div class="flex h-6 shrink-0 items-center">
                    <input 
                      id="terms" 
                      name="terms" 
                      type="checkbox" 
                      v-model="acceptTerms"
                      class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <label for="terms" class="text-sm leading-6 text-gray-500">
                    I agree to the 
                    <a href="https://www.sondar.ai/terms-of-service" target="_blank" class="font-semibold text-indigo-600 hover:text-indigo-500">Terms</a>
                    and
                    <a href="https://www.sondar.ai/privacy" target="_blank" class="font-semibold text-indigo-600 hover:text-indigo-500">Privacy Policy</a>
                  </label>
                </div>
              </div>

              <div>
                <button 
                  type="submit"
                  :disabled="!isPasswordValid || !acceptTerms || isLoading"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                >
                  <template v-if="isLoading">
                    <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Creating account...
                  </template>
                  <template v-else>
                    Sign up
                  </template>
                </button>
              </div>

              <p v-if="formError" class="mt-4 text-sm text-red-600">{{ formError }}</p>

              <p class="mt-10 text-center text-sm text-gray-500">
                Already have an account?
                <router-link to="/login" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  Sign in
                </router-link>
              </p>
            </form>
          </div>
        </div>
      </div>

      <!-- Right side with background image -->
      <div class="relative hidden xl:block">
        <img
          class="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt="Office workspace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'AuthSignUp',
  data() {
    return {
      name: '',
      email: '',
      organizationName: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
      nameError: '',
      emailError: '',
      organizationError: '',
      passwordError: '',
      confirmPasswordError: '',
      formError: '',
      isLoading: false,
      isPasswordValid: false,
      showConfirmationModal: false,
      passwordChecklist: {
        length: { message: "At least 8 characters", met: false },
        uppercase: { message: "At least one uppercase letter", met: false },
        number: { message: "At least one number", met: false },
        special: { message: "At least one special character", met: false },
      },
    };
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = emailRegex.test(this.email) ? '' : 'Please enter a valid email address';
    },
    validatePassword() {
      const length = this.password.length >= 8;
      const uppercase = /[A-Z]/.test(this.password);
      const number = /\d/.test(this.password);
      const special = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);

      this.passwordChecklist.length.met = length;
      this.passwordChecklist.uppercase.met = uppercase;
      this.passwordChecklist.number.met = number;
      this.passwordChecklist.special.met = special;

      this.isPasswordValid = length && uppercase && number && special;
      this.passwordError = this.isPasswordValid ? "" : "Password does not meet requirements";

      this.validateConfirmPassword();
    },
    validateConfirmPassword() {
      if (this.password !== this.confirmPassword && this.confirmPassword.length > 0) {
        this.confirmPasswordError = 'Passwords do not match';
      } else {
        this.confirmPasswordError = '';
      }
    },
    async handleSignUp() {
      // Reset errors
      this.nameError = '';
      this.emailError = '';
      this.organizationError = '';
      this.passwordError = '';
      this.confirmPasswordError = '';
      this.formError = '';

      // Validate inputs
      if (!this.name) {
        this.nameError = 'Name is required';
        return;
      }
      if (!this.email) {
        this.emailError = 'Email is required';
        return;
      }
      if (!this.organizationName) {
        this.organizationError = 'Organization name is required';
        return;
      }
      if (!this.password) {
        this.passwordError = 'Password is required';
        return;
      }
      if (!this.acceptTerms) {
        this.formError = 'You must accept the terms and conditions';
        return;
      }

      try {
        this.isLoading = true;
        const authStore = useAuthStore();
        await authStore.signUp({
          name: this.name,
          email: this.email,
          organizationName: this.organizationName,
          password: this.password,
        });
        this.showConfirmationModal = true;
      } catch (err) {
        this.formError = err.message || 'An unexpected error occurred. Please try again later.';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script> 