<template>
  <div id="tenant-settings" class="w-full text-left">
    <!-- Loading State -->
    <div v-if="isLoading" class="flex items-center justify-center w-full">
      <div class="p-6 space-y-6  w-[75rem] animate-pulse">
        <!-- Tabs -->
        <div class="flex space-x-4">
          <div class="h-8 w-24 bg-gray-300 rounded"></div>
          <div class="h-8 w-36 bg-gray-200 rounded"></div>
        </div>

        <!-- Organization Profile -->
        <div class="space-y-4">
          <div class="h-6 w-40 bg-gray-300 rounded"></div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
        </div>

        <!-- My Profile -->
        <div class="space-y-4">
          <div class="h-6 w-40 bg-gray-300 rounded"></div>
          <div class="space-y-2">
            <div class="h-4 w-24 bg-gray-200 rounded"></div>
            <div class="h-10 w-full bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <GlobalNotification :key="ok" v-if="showSuccessNotification" :type="'ok'" :message="successNotificationMsg"
        @close="showSuccessNotification = false" />

      <!-- Page Heading -->
      <div class="w-[1080px] mx-auto px-5 pt-5 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl/7 font-bold text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Settings
          </h2>
        </div>
      </div>

      <!-- Tab Menus -->
      <div class="border-b border-gray-200 sm:pb-0">
        <div class="w-[1080px] mx-auto px-5 pt-5">
          <div class="mt-3 sm:mt-4">
            <div class="hidden sm:block">
              <nav class="-mb-px flex space-x-8">
                <a v-for="tab in tabs" :key="tab.name" :href="tab.href" :class="[
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap cursor-pointer border-b-2 px-1 pb-4 text-sm font-medium',
                ]" :aria-current="tab.current ? 'page' : undefined" @click.prevent="changeTab(tab.name)">
                  {{ tab.name }}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <!-- Tab Content -->
      <div class="w-[1080px] mx-auto p-5">
        <!-- Profile Section -->
        <div v-if="tabs.find((tab) => tab.current && tab.name === 'Profile')" id="profile">
          <div class="px-4 sm:px-0">
            <h3 class="text-base/7 font-semibold text-gray-900">Organization Profile</h3>
          </div>
          <div class="mt-6 border-t border-b border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
                <div class="text-sm/6 font-medium text-gray-900">Name</div>
                <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ tenantName }}
                </div>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <div class="text-sm/6 font-medium text-gray-900">API Key</div>
                <div class="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0 relative">
                  <div class="group relative">
                    <div
                      class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-gray-50 group-hover:ring-2 group-hover:ring-inset group-hover:ring-indigo-600 transition-all duration-200">
                      <div class="flex-1 py-2.5 pl-3 pr-12 text-gray-900 font-mono text-sm truncate">
                        {{ tenantApiKey }}
                      </div>
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <button type="button"
                          class="inline-flex h-full items-center rounded-r-md px-3 hover:focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 group/button transition-colors duration-200"
                          @click="copyToClipboard">
                          <svg
                            class="h-4 w-4 text-gray-400 group-hover/button:text-indigo-600 transition-colors duration-200"
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round"
                              d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
                          </svg>
                          <span class="sr-only">Copy API key</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <p class="mt-2 text-xs text-gray-500">
                    Use this key to authenticate API requests from your application.
                  </p>
                </div>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <div class="text-sm/6 font-medium text-gray-900">JavaScript SDK</div>
                <div class="mt-2 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  <div class="flex items-center gap-x-2">
                    <div class="h-2.5 w-2.5 rounded-full" :class="sdkConnected ? 'bg-green-500' : 'bg-red-500'"></div>
                    <span class="text-sm text-gray-600">{{ sdkConnected ? 'Connected' : 'Not Connected' }}</span>
                  </div>
                </div>
              </div>
            </dl>
          </div>

          <!-- My Profile Section -->
          <div class="mt-16 px-4 sm:px-0">
            <h3 class="text-base/7 font-semibold text-gray-900">My Profile</h3>
          </div>
          <div class="mt-6 border-t border-b border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
                <div class="text-sm/6 font-medium text-gray-900">Name</div>
                <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ currentUser.name || '-' }}
                </div>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0 group">
                <div class="text-sm/6 font-medium text-gray-900">Email</div>
                <div class="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {{ currentUser.email }}
                </div>
              </div>
            </dl>
          </div>
        </div>

        <!-- Team Members Section -->
        <div v-if="tabs.find((tab) => tab.current && tab.name === 'Team Members')" id="team-members">
          <div class="flex justify-between items-center mb-4">
            <h3>Team Members</h3>
            <button v-if="userRole === 'tenant_admin'" type="button" class="btn-primary-sondar"
              @click="showPopup = true">
              Invite
            </button>
          </div>

          <!-- Team Members List -->
          <div class="overflow-hidden border-t border-gray-200">
            <div class="bg-white">
              <table class="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Email
                    </th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Role</th>
                    <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                    <th v-if="userRole === 'tenant_admin'" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span class="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(user, index) in teamMembers" :key="index" class="hover:bg-gray-50">
                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                      <div class="flex items-center">
                        <div class="h-8 w-8 flex-shrink-0 rounded-full bg-indigo-100 flex items-center justify-center">
                          <span class="text-xs font-medium text-indigo-700">{{ getInitials(user.email) }}</span>
                        </div>
                        <div class="ml-4">
                          <div class="font-medium text-gray-900">{{ user.email }}</div>
                        </div>
                      </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                      <span :class="[
                        user.role === 'tenant_admin'
                          ? 'bg-blue-50 text-blue-700 ring-blue-600/20'
                          : 'bg-green-50 text-green-700 ring-green-600/20',
                        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                      ]">
                        {{ user.role === 'tenant_admin' ? 'Admin' : 'Member' }}
                      </span>
                    </td>
                    <td class="whitespace-nowrap px-3 py-4 text-sm">
                      <span :class="[
                        user.is_active
                          ? 'bg-green-50 text-green-700 ring-green-600/20'
                          : 'bg-gray-50 text-gray-600 ring-gray-500/10',
                        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                      ]">
                        {{ user.is_active ? 'Active' : 'Paused' }}
                      </span>
                    </td>
                    <td v-if="userRole === 'tenant_admin'"
                      class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button v-if="user.email !== currentUser.email" @click="editMember(user)"
                        class="rounded-md p-2 text-gray-400 hover:bg-gray-50 hover:text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                          stroke="currentColor" class="h-5 w-5">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                        <span class="sr-only">Edit {{ user.email }}</span>
                      </button>
                      <span v-else class="text-xs text-gray-400 mr-2">
                        (You)
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Invite Team Member Modal -->
      <div v-if="showPopup && canInviteMembers" class="relative z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto text-left">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="relative">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    Invite Team Member
                  </h3>

                  <div class="mt-6">
                    <div class="mb-4">
                      <label for="teamMemberName" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                      <div class="mt-2">
                        <input id="teamMemberName" v-model="teamMemberName" type="text" placeholder="e.g. Elon Musk"
                          class="input-sondar" />
                        <p v-if="teamMemberNameError" class="mt-2 text-sm text-red-600">
                          {{ teamMemberNameError }}
                        </p>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label for="teamMemberEmail"
                        class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                      <div class="mt-2">
                        <input id="teamMemberEmail" v-model="teamMemberEmail" type="email"
                          placeholder="Enter email address" class="input-sondar" />
                        <p v-if="emailError" class="mt-2 text-sm text-red-600">
                          {{ emailError }}
                        </p>
                      </div>
                    </div>

                    <div class="mb-4">
                      <label for="teamMemberRole" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
                      <div class="mt-2">
                        <select id="teamMemberRole" v-model="teamMemberRole" class="select-sondar">
                          <option value="" disabled>Select role</option>
                          <option value="3">Member</option>
                          <option value="2">Admin</option>
                        </select>
                        <p class="mt-2 text-xs text-gray-500">
                          Admins can manage team members and settings. Members can only manage surveys.
                        </p>
                        <p v-if="teamMemberRoleError" class="mt-2 text-sm text-red-600">
                          {{ teamMemberRoleError }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <!-- Action Buttons -->
                  <div class="mt-8 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button type="button" @click="clearModal()" class="btn-secondary-sondar">
                      Cancel
                    </button>
                    <button 
                      type="button" 
                      @click="inviteTeamMember(teamMemberName, teamMemberEmail)"
                      class="btn-primary-sondar"
                      :disabled="isInviting"
                    >
                      <div v-if="isInviting" class="flex items-center justify-center">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      </div>
                      <span v-else>Invite</span>
                    </button>
                  </div>

                  <!-- General Error Message -->
                  <div v-if="errorMessage" class="mt-4 rounded-md bg-red-50 p-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                            clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <p class="text-sm font-medium text-red-800">{{ errorMessage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Member Modal -->
      <div v-if="showEditModal" class="relative z-50">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">
                    Edit Team Member
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      {{ selectedMember?.email }}
                    </p>
                  </div>
                </div>

                <div class="mt-6 space-y-6">
                  <div>
                    <label for="editRole" class="block text-sm font-medium text-gray-700">Role</label>
                    <select id="editRole" v-model="editForm.role"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option value="tenant_member">Member</option>
                      <option value="tenant_admin">Admin</option>
                    </select>
                  </div>

                  <div>
                    <label for="editStatus" class="block text-sm font-medium text-gray-700">Status</label>
                    <select id="editStatus" v-model="editForm.is_active"
                      class="mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                      <option :value="true">Active</option>
                      <option :value="false">Paused</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1"
                  @click="showEditModal = false">
                  Cancel
                </button>
                <button type="button"
                  class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  @click="updateMember">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/vue/20/solid";
import GlobalNotification from "../GlobalNotification.vue";
import supabase from "../../supabase";
import { useAuthStore } from '@/stores/auth'

export default {
  name: "TenantSettings",
  components: {
    GlobalNotification,
  },
  computed: {
    canInviteMembers() {
      return this.userRole === 'tenant_admin';
    }
  },
  data() {
    return {
      tabs: [
        { name: "Profile", current: true },
        { name: "Team Members", current: false },
      ],
      currentUser: {},
      userRole: null,
      teamMembers: [],
      tenantApiKey: "",
      tenantName: "",
      sdkConnected: false,
      pollingInterval: null,
      showSuccessNotification: false,
      successNotificationMsg: "",
      showPopup: false,
      errorMessage: "",
      isLoading: false,
      isInviting: false,
      tenantId: null,
      teamMemberName: "",
      teamMemberEmail: "",
      teamMemberRole: "",
      emailError: "",
      teamMemberNameError: "",
      teamMemberRoleError: "",
      showEditModal: false,
      selectedMember: null,
      editForm: {
        role: 'tenant_member',
        is_active: true
      },
    };
  },
  async mounted() {
    const authStore = useAuthStore();
    this.tenantId = authStore.tenantId;
    this.userRole = authStore.role;

    this.isLoading = true;
    if (this.tenantId) {
      try {
        await Promise.all([
          this.getCurrentUser(),
          this.getTenantDetails(),
          this.getTeamMembers()
        ]);
      } catch (error) {
        console.error("Error loading settings:", error);
      } finally {
        this.isLoading = false;
      }
    }

    if (this.tabs.length > 0) {
      this.changeTab(this.tabs[0].name);
    }
  },
  methods: {
    changeTab(tabName) {
      this.tabs.forEach((tab) => {
        tab.current = tab.name === tabName;
      });
    },
    async getCurrentUser() {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        
        if (user) {
          const { data: userData, error } = await supabase
            .from('users')
            .select('name, email')
            .eq('id', user.id)
            .single();

          if (error) throw error;

          this.currentUser = {
            ...user,
            name: userData.name
          };
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    },
    async getTenantDetails() {
      try {
        const { data, error } = await supabase
          .from("tenants")
          .select()
          .eq("id", this.tenantId)
          .single();

        if (error) throw error;

        if (data) {
          this.tenantApiKey = data.api_key;
          this.tenantName = data.name;
          this.sdkConnected = data.sdk_connected;

          if (!this.sdkConnected) {
            this.pollingInterval = setInterval(() => {
              this.checkSDKConnection();
            }, 3000);
          }
        }
      } catch (error) {
        console.error("Error getting Tenant Details:", error.message);
      }
    },
    async getTeamMembers() {
      try {
        const { data, error } = await supabase
          .from("users")
          .select("email, name, roles(name), is_active")
          .eq("tenant_id", this.tenantId);

        if (error) throw error;

        if (data) {
          this.teamMembers = data.map(user => ({
            email: user.email,
            name: user.name,
            role: user.roles.name,
            is_active: user.is_active
          }));
        }
      } catch (error) {
        console.error("Error getting Team Member data:", error.message);
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.tenantApiKey);
        this.successNotificationMsg = "Copied to Clipboard";
        this.showSuccessNotification = true;
        setTimeout(() => this.showSuccessNotification = false, 4000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
    async inviteTeamMember() {
      // Check permissions
      if (!this.canInviteMembers) {
        this.errorMessage = "You don't have permission to invite team members";
        return;
      }

      // Reset all errors
      this.emailError = "";
      this.teamMemberNameError = "";
      this.errorMessage = "";

      // Validate all fields
      let hasError = false;

      if (!this.teamMemberName) {
        this.teamMemberNameError = "Name is required";
        hasError = true;
      }

      if (!this.teamMemberEmail) {
        this.emailError = "Email is required";
        hasError = true;
      } else if (!this.isValidEmail(this.teamMemberEmail)) {
        this.emailError = "Please enter a valid email address";
        hasError = true;
      }

      if (!this.teamMemberRole) {
        this.teamMemberRoleError = "Please select a role";
        hasError = true;
      }

      if (hasError) return;

      this.isInviting = true;
      try {
        const { data: existingUser, error: checkError } = await supabase
          .from("users")
          .select("email")
          .eq("email", this.teamMemberEmail)
          .single();

        if (existingUser) {
          this.emailError = "Cannot invite this user. This email is already registered.";
          return;
        }

        if (checkError && checkError.code !== 'PGRST116') {
          throw checkError;
        }

        const { data: userData, error: userError } = await supabase.auth.admin.inviteUserByEmail(
          this.teamMemberEmail,
          {
            redirectTo: `${window.location.protocol}//${window.location.host}/reset-password`,
          }
        );

        if (userError) throw userError;

        const { error: userTableError } = await supabase
          .from("users")
          .insert({
            id: userData.user.id,
            email: this.teamMemberEmail,
            role_id: parseInt(this.teamMemberRole),
            tenant_id: this.tenantId,
          });

        if (userTableError) throw userTableError;

        this.showPopup = false;
        this.successNotificationMsg = "Team member invited successfully";
        this.showSuccessNotification = true;
        await this.getTeamMembers();
        setTimeout(() => this.showSuccessNotification = false, 4000);
      } catch (error) {
        // Handle specific error cases
        if (error.message.includes('email')) {
          this.emailError = error.message;
        } else {
          this.errorMessage = "Failed to invite team member. Please try again.";
        }
      } finally {
        this.isInviting = false;
      }
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    clearModal() {
      this.showPopup = false;
      this.teamMemberName = "";
      this.teamMemberEmail = "";
      this.teamMemberRole = "";
      this.emailError = "";
      this.teamMemberNameError = "";
      this.teamMemberRoleError = "";
      this.errorMessage = "";
    },
    getInitials(email) {
      // Get the part before @ in email
      const name = email.split('@')[0];
      // Split by special characters and get first letter of each part
      const initials = name
        .split(/[._-]/)
        .map(part => part[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
      return initials;
    },
    editMember(user) {
      this.selectedMember = user;
      this.editForm.role = user.role === 'tenant_admin' ? 'tenant_admin' : 'tenant_member';
      this.editForm.is_active = user.is_active;
      this.showEditModal = true;
    },
    async updateMember() {
      try {
        const { error } = await supabase
          .from('users')
          .update({
            role_id: this.editForm.role === 'tenant_admin' ? 2 : 3,
            is_active: this.editForm.is_active
          })
          .eq('email', this.selectedMember.email)
          .eq('tenant_id', this.tenantId);

        if (error) throw error;

        this.showEditModal = false;
        this.successNotificationMsg = "Team member updated successfully";
        this.showSuccessNotification = true;
        await this.getTeamMembers();
        setTimeout(() => this.showSuccessNotification = false, 4000);
      } catch (error) {
        this.errorMessage = "Failed to update team member. Please try again.";
      }
    },
    async checkSDKConnection() {
      try {
        const { data, error } = await supabase
          .from("tenants")
          .select("sdk_connected")
          .eq("id", this.tenantId)
          .single();

        if (error) throw error;

        this.sdkConnected = data.sdk_connected;

        if (this.sdkConnected) {
          // Stop polling if connected
          clearInterval(this.pollingInterval);
        }
      } catch (error) {
        console.error("Error checking SDK connection:", error);
      }
    },
  },
  beforeUnmount() {
    // Clean up polling interval when component is destroyed
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  },
};
</script>