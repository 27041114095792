<template>
    <div class="p-6">
        <!-- Loading Spinner -->
        <div v-if="isLoading">
            <div class="min-h-screen animate-pulse space-y-6 bg-gray-50 p-6">
                <div>
                    <div class="mb-2 h-6 w-32 rounded bg-gray-200"></div>
                    <div class="h-10 w-full rounded bg-gray-300"></div>
                </div>
                <div>
                    <div class="mb-2 h-6 w-56 rounded bg-gray-200"></div>
                    <div class="flex space-x-4">
                        <div class="h-16 flex-1 rounded bg-gray-300"></div>
                        <div class="h-16 flex-1 rounded bg-gray-300"></div>
                    </div>
                </div>
                <div>
                    <div class="mb-2 h-6 w-40 rounded bg-gray-200"></div>
                    <div class="flex items-center space-x-4">
                        <div class="h-10 w-32 rounded bg-gray-300"></div>
                        <div class="h-16 w-24 rounded bg-gray-200"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="space-y-8" v-if="!isLoading">

            <!-- Distribution Channel -->
            <div class="flex gap-4">
                <div class="flex flex-col mt-3 w-1/4">
                    <label for="context" class="block text-medium text-left font-semibold text-gray-900">Distribution
                        Channel</label>
                    <span class="text-sm text-left font-medium text-gray-500">Select the channel where this study will
                        appear.</span>
                </div>
                <div class="mt-2 w-full">
                    <div class="flex flex-col" style="width: fit-content;">
                        <div class="flex items-center space-x-4">
                            <div @click="distributionChannel = 'Shareable Link'" :class="{
                                'border-indigo-600 border-2': distributionChannel === 'Shareable Link',
                                'border border-gray-300': distributionChannel !== 'Shareable Link',
                            }"
                                class="p-4 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 flex flex-col cursor-pointer">
                                <span class="text-left text-sm font-medium font-semibold">Shareable Link</span>
                                <span class="text-left text-sm font-medium text-gray-500">Send this survey to anyone via
                                    a
                                    unique
                                    URL</span>
                            </div>

                            <div @click="distributionChannel = 'Web'" :class="{
                                'border-indigo-600 border-2': distributionChannel === 'Web',
                                'border border-gray-300': distributionChannel !== 'Web',
                            }"
                                class="p-4 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 flex flex-col cursor-pointer">
                                <span class="text-left text-sm font-medium font-semibold">Web</span>
                                <span class="text-left text-sm font-medium text-gray-500">Show studies to visitors to
                                    your
                                    website
                                    or app</span>
                            </div>
                        </div>
                        <template v-if="distributionChannel === 'Shareable Link'">
                            <div class="mt-2 bg-gray-100 py-3 px-4 rounded-lg flex justify-between items-center">
                                <a :href="getSharableLink" class="text-sm">{{ getSharableLink }}</a>
                                <button @click="copyToClipboard"
                                    class="text-gray-600 hover:text-indigo-600 focus:outline-none"
                                    title="Copy to clipboard">
                                    <ClipboardIcon v-if="!copied" class="h-5 w-5 transition duration-300 ease-in-out" />
                                    <CheckCircleIcon v-else
                                        class="h-5 w-5 text-green-500 transition duration-300 ease-in-out" />
                                </button>
                            </div>
                        </template>
                    </div>

                </div>
            </div>
            <!-- Position Field -->
            <div class="flex gap-4">
                <div class="flex flex-col mt-3 w-1/4">
                    <label for="context"
                        class="block text-medium text-left font-semibold text-gray-900">Position</label>
                    <span class="text-sm text-left font-medium text-gray-500">Choose where the Sondar widget will appear
                        in
                        your product.</span>
                </div>
                <div class="mt-2 w-full">
                    <div class="flex flex-col gap-2 items-center" style="width:fit-content">
                        <select id="position" v-model="widget_position" @change="debounceUpdateWidgetPosition"
                            class="block p-3 w-full rounded-lg border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                            <option v-for="position in positions" :key="position.value" :value="position.value">
                                {{ position.label }}
                            </option>
                        </select>
                        <WidgetPosition :position="widget_position" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WidgetPosition from './WidgetPosition.vue';
import supabase from "../../../src/supabase";
import { ClipboardIcon, CheckCircleIcon } from '@heroicons/vue/24/outline';

export default {
    name: "ShareSurvey",
    components: { WidgetPosition, ClipboardIcon, CheckCircleIcon },
    mounted() {
        this.fetchSurvey();
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard
                .writeText(this.getSharableLink)
                .then(() => {
                    this.copied = true;
                    setTimeout(() => {
                        this.copied = false;
                    }, 2000);
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                });
        },
        async fetchSurvey() {
            try {
                const { data, error } = await supabase
                    .from('tenant_surveys')
                    .select("widget_position, llm_context")
                    .eq('id', this.survey_id)
                    .single();

                if (error) {
                    console.error('Error fetching tenant_surveys:', error);
                } else {
                    this.context = data.llm_context;
                    this.widget_position = data.widget_position;
                }
            } catch (err) {
                console.error('Unexpected error:', err);
            } finally {
                this.isLoading = false;
            }
        },
        debounce(func, wait) {
            let timeout;
            return (...args) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(this, args), wait);
            };
        },
        async updateContext() {
            try {
                const { error } = await supabase
                    .from('tenant_surveys')
                    .update({ llm_context: this.context })
                    .eq('id', this.survey_id);

                if (error) {
                    console.error('Error updating context:', error);
                } else {
                    console.log('Context successfully updated');
                }
            } catch (err) {
                console.error('Unexpected error updating context:', err);
            }
        },
        async updateWidgetPosition() {
            try {
                const { error } = await supabase
                    .from('tenant_surveys')
                    .update({ widget_position: this.widget_position })
                    .eq('id', this.survey_id);

                if (error) {
                    console.error('Error updating widget position:', error);
                } else {
                    console.log('Widget position successfully updated');
                }
            } catch (err) {
                console.error('Unexpected error updating widget position:', err);
            }
        },
        debounceUpdateContext: null,
        debounceUpdateWidgetPosition: null,
    },
    created() {
        this.debounceUpdateContext = this.debounce(this.updateContext, 2000);
        this.debounceUpdateWidgetPosition = this.debounce(this.updateWidgetPosition, 2000);
    },
    computed: {
        getSharableLink() {
            return `${window.location.origin}/survey/${this.survey_id}`;
        },
    },
    data() {
        return {
            context: "",
            copied: false,
            survey_id: this.$route.params.survey_id,
            distributionChannel: "Shareable Link",
            widget_position: "bottom_center",
            isLoading: true,
            positions: [
                { value: "top_left", label: "Top Left" },
                { value: "top_center", label: "Top Center" },
                { value: "top_right", label: "Top Right" },
                { value: "center_left", label: "Center Left" },
                { value: "page_center", label: "Page Center" },
                { value: "center_right", label: "Center Right" },
                { value: "bottom_left", label: "Bottom Left" },
                { value: "bottom_center", label: "Bottom Center" },
                { value: "bottom_right", label: "Bottom Right" },
            ],
        };
    },
};
</script>

<style scoped>
button {
    outline: none;
    cursor: pointer;
}
</style>
