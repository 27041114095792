<template>
    <div class="min-h-screen bg-white">
        <!-- Main container with narrower width -->
        <div class="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 py-8">
            <!-- Breadcrumb -->
            <nav class="flex mb-12" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
                    <li class="flex">
                        <div class="flex items-center">
                            <router-link to="/surveys" class="text-gray-400 hover:text-gray-500">
                                <HomeIcon class="h-5 w-5 shrink-0" aria-hidden="true" />
                            </router-link>
                            <router-link to="/templates" class="text-gray-400 hover:text-gray-500">
                                <span class="sr-only">Templates</span>
                            </router-link>
                        </div>
                    </li>
                    <li v-for="page in breadcrumbs" :key="page.name" class="flex">
                        <div class="flex items-center">
                            <svg class="h-full w-6 shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
                                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                            </svg>
                            <router-link 
                                :to="page.href" 
                                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                :class="{ 'text-indigo-600': page.current }"
                                :aria-current="page.current ? 'page' : undefined"
                            >
                                {{ page.name }}
                            </router-link>
                        </div>
                    </li>
                </ol>
            </nav>

            <!-- Add this after the breadcrumb and before the content -->
            <div v-if="isLoading" class="animate-pulse">
                <div class="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
                <div class="h-4 bg-gray-200 rounded w-2/3 mb-8"></div>
                <div class="space-y-4">
                    <div class="h-4 bg-gray-200 rounded w-full"></div>
                    <div class="h-4 bg-gray-200 rounded w-5/6"></div>
                    <div class="h-4 bg-gray-200 rounded w-4/6"></div>
                </div>
            </div>

            <!-- Wrap the main content in v-else -->
            <div v-else>
                <!-- Header section with more compact spacing -->
                <div class="mb-8">
                    <h1 class="text-2xl font-bold text-gray-900 mb-4 text-left">
                        {{ template.title }}
                    </h1>
                    <p class="text-md text-gray-600 text-left">
                        {{ description }}
                    </p>
                    
                    <!-- Action buttons with proper spacing -->
                    <div class="mt-4 flex gap-4 justify-start">
                        <button
                            type="button"
                            class="inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            @click="previewTemplate"
                        >
                            Preview Template
                        </button>
                        <button
                            type="button"
                            class="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            @click="useTemplate"
                        >
                            Use Template
                        </button>
                    </div>
                </div>

                <!-- Add this after the title and description -->
                <div v-if="thumbnail" class="mt-6 mb-8">
                    <img 
                        :src="thumbnail" 
                        :alt="template.title"
                        class="w-full rounded-lg shadow-sm"
                    />
                </div>

                <!-- Content sections with proper spacing -->
                <div class="space-y-8">
                    <template v-for="[title, content] in sortedSections" :key="title">
                        <section :aria-labelledby="`${title.toLowerCase().replace(/\s+/g, '-')}-heading`">
                            <h2 
                                :id="`${title.toLowerCase().replace(/\s+/g, '-')}-heading`" 
                                class="text-lg font-bold text-gray-900 mb-3 text-left"
                            >
                                {{ title }}
                            </h2>
                            <div 
                                class="text-sm text-gray-600 leading-relaxed text-left"
                                v-html="content"
                            ></div>
                        </section>
                    </template>
                </div>
            </div>
        </div>

        <!-- Add the confirmation modal -->
        <TransitionRoot as="template" appear :show="showCloneModal">
            <Dialog as="div" class="relative z-50" @close="showCloneModal = false">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enter-to="opacity-100 translate-y-0 sm:scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 translate-y-0 sm:scale-100"
                            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div class="sm:flex sm:items-start">
                                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                                            Clone Template
                                        </DialogTitle>
                                        <div class="mt-2">
                                            <p class="text-sm text-gray-500">
                                                Are you sure you want to clone this template? A copy will be created in your surveys.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                                        @click="cloneTemplate"
                                        :disabled="isCloning"
                                    >
                                        <template v-if="isCloning">
                                            <svg class="animate-spin h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </template>
                                        <template v-else>
                                            Yes, Clone it
                                        </template>
                                    </button>
                                    <button
                                        type="button"
                                        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        @click="showCloneModal = false"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/20/solid'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import supabase from "../../../src/supabase"
import { useAuthStore } from '@/stores/auth'

export default {
    name: 'PreviewTemplate',
    components: {
        HomeIcon,
        Dialog,
        DialogPanel,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    props: {
        templateId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            tenantId: null,
            template: {
                title: '',
                description: '',
                metadata: {
                    description: '',
                    template_content: {},
                    category: '',
                    thumbnail: null,
                    preview_url: null
                }
            },
            breadcrumbs: [
                { name: 'Templates', href: '/templates', current: false },
                { name: '', href: '#', current: true },
            ],
            isLoading: true,
            showCloneModal: false,
            isCloning: false,
        }
    },
    computed: {
        sortedSections() {
            const order = [
                'Thumbnail',
                'When to Use',
                'Target Audience',
                'What You Will Learn',
                'Questions'
            ];

            // Safely access template content
            const templateContent = this.template?.metadata?.template_content || {};
            const sections = Object.entries(templateContent);

            return sections.sort((a, b) => {
                const indexA = order.indexOf(a[0]);
                const indexB = order.indexOf(b[0]);
                
                // If both sections are in the order array, sort by their position
                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                }
                // If only one section is in the order array, prioritize it
                if (indexA !== -1) return -1;
                if (indexB !== -1) return 1;
                // If neither section is in the order array, maintain their original order
                return 0;
            });
        },
        categories() {
            return this.template?.metadata?.category?.split(',').map(cat => cat.trim()) || [];
        },
        description() {
            return this.template?.metadata?.description || 'No description available';
        },
        thumbnail() {
            return this.template?.metadata?.thumbnail || null;
        }
    },
    methods: {
        async fetchTemplate() {
            try {
                this.isLoading = true;
                const { data: template, error } = await supabase
                    .from('tenant_surveys')
                    .select('*, survey_blocks (*)')
                    .eq('id', this.templateId)
                    .single();

                if (error) throw error;

                if (template) {
                    this.template = template;
                    this.breadcrumbs[1].name = template.title;
                    this.incrementStat('views_count');
                }
            } catch (error) {
                console.error('Error fetching template:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async incrementStat(statType) {
            try {
                const { data: existingStat } = await supabase
                    .from('survey_stats')
                    .select()
                    .eq('survey_id', this.templateId)
                    .single();

                if (existingStat) {
                    await supabase
                        .from('survey_stats')
                        .update({
                            [statType]: existingStat[statType] + 1,
                            updated_at: new Date().toISOString()
                        })
                        .eq('survey_id', this.templateId);
                } else {
                    await supabase
                        .from('survey_stats')
                        .insert({
                            survey_id: this.templateId,
                            [statType]: 1,
                            created_at: new Date().toISOString(),
                            updated_at: new Date().toISOString()
                        });
                }
            } catch (error) {
                console.error(`Error incrementing ${statType}:`, error);
            }
        },
        async previewTemplate() {
            window.open(`/survey/${this.templateId}?isPreview=true`, '_blank');
        },
        async useTemplate() {
            this.showCloneModal = true;
        },
        async cloneTemplate() {
            try {
                this.isCloning = true;
                await this.incrementStat('clones_count');
                const { data: template } = await supabase
                    .from('tenant_surveys')
                    .select('*, survey_blocks (*)')
                    .eq('id', this.templateId)
                    .single();

                if (!template) throw new Error('Template not found');
                const { data: newSurvey, error: surveyError } = await supabase
                    .from('tenant_surveys')
                    .insert({
                        trigger_events: template.trigger_events,
                        conditions: template.conditions,
                        user_attrs: template.user_attrs,
                        delay_seconds: template.delay_seconds,
                        can_dismiss: template.can_dismiss,
                        llm_context: template.llm_context,
                        trigger_type: template.trigger_type,
                        widget_position: template.widget_position,
                        title: `${template.title} (Cloned)`,
                        tenant_id: this.tenantId || template.tenant_id,
                        is_template: false,
                        metadata: null,
                        status: template.status,
                    })
                    .select()
                    .single();

                if (surveyError) throw surveyError;

                // Clone survey blocks
                if (template.survey_blocks && template.survey_blocks.length > 0) {
                    const blocksToInsert = template.survey_blocks.map(block => ({
                        button_label: block.button_label,
                        button_url: block.button_url,
                        description: block.description,
                        followup_question_prompt: block.followup_question_prompt,
                        followup_questions: block.followup_questions,
                        rating_max_label: block.rating_max_label,
                        rating_min_label: block.rating_min_label,
                        position: block.position,
                        required: block.required,
                        rating_max: block.rating_max,
                        rating_min: block.rating_min,
                        survey_id: newSurvey.id,  // Link to the new survey
                        title: block.title,
                        type: block.type,
                        choices: block.choices
                    }));

                    const { error: blocksError } = await supabase
                        .from('survey_blocks')
                        .insert(blocksToInsert);

                    if (blocksError) throw blocksError;
                }

                // Close modal and redirect to edit page
                this.showCloneModal = false;
                this.$router.push(`/`);

            } catch (err) {
                console.error('Error cloning template:', err);
                alert('Failed to clone template. Please try again.');
            } finally {
                this.isCloning = false;
            }
        }
    },
    async mounted() {
        const authStore = useAuthStore();
        this.tenantId = authStore.tenantId;
        await this.fetchTemplate();
    }
}
</script> 