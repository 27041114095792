<template>
  <div class="outer-admin">
    <div class="flex h-screen">
      <div class="flex w-60 flex-col border-r border-gray-200">
        <NavBar :active-id="activeId" @active-id="updateActiveId" />
      </div>
      <div class="flex justify-center items-start w-full overflow-y-auto">
        <router-view v-if="activeId === 'surveys'" v-slot="{ route }">
          <SurveyBuilder v-if="route.name === 'SurveyBuilder'" />
          <SurveyList v-else />
        </router-view>
        <EventList v-if="activeId === 'events'" />
        <AttributeList v-if="activeId === 'attributes'" />
        <AdminSettings v-if="activeId === 'admin-settings'" />
        <TenantSettings v-if="activeId === 'tenant-settings'" />
      </div>
    </div>
  </div>
</template>


<script>
import NavBar from "./NavBar.vue";
import SurveyList from "./SurveyList.vue";
import SurveyBuilder from "./EditSurvey.vue";
import EventList from "./EventList.vue";
import AttributeList from "./AttributeList.vue";
import AdminSettings from "./AdminSettings.vue";
import TenantSettings from "./TenantSettings.vue";

export default {
  components: {
    NavBar,
    SurveyList,
    SurveyBuilder,
    EventList,
    AttributeList,
    AdminSettings,
    TenantSettings,
  },
  name: "AdminDashboard",
  data() {
    return {
      activeId: this.determineActiveId(),
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.activeId = this.determineActiveId();
      },
    },
  },
  methods: {
    updateActiveId(id) {
      this.activeId = id;
    },
    determineActiveId() {
      const path = this.$route.path;
      if (path == '/') return "surveys";
      if (path.startsWith("/events")) return "events";
      if (path.startsWith("/attributes")) return "attributes";
      if (path.startsWith("/survey")) return "surveys";
      if (path.startsWith("/admin-settings")) return "admin-settings";
      if (path.startsWith("/tenant-settings")) return "tenant-settings";
    },
  },
};
</script>
