<template>
    <div class="p-6">
        <div class="max-w-[75rem] mx-auto">
            <!-- Page header -->
            <div class="mb-8">
                <div class="flex items-center justify-between">
                    <div class="flex-1">
                        <h1 class="text-2xl font-bold text-gray-900 text-left">Templates</h1>
                        <p class="mt-2 text-sm text-gray-600 text-left max-w-2xl">
                            Browse our collection of professionally designed survey templates. Each template is crafted
                            to
                            help you gather meaningful insights and can be fully customized to match your specific
                            needs.
                        </p>
                    </div>
                    <!-- Search box -->
                    <div class="relative rounded-md shadow-sm w-72">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input type="text" v-model="searchQuery"
                            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Search templates..." />
                    </div>
                </div>
            </div>

            <div v-if="isLoading" class="flex gap-4 p-4">
                <!-- Sidebar Skeleton -->
                <div class="w-1/4 space-y-4">
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                    <div class="h-6 rounded bg-gray-300"></div>
                </div>

                <!-- Main Content Skeleton -->
                <div class="flex-1 space-y-4">
                    <!-- Cards Wrapper -->
                    <div class="grid grid-cols-3 gap-4">
                        <!-- Card Skeleton -->
                        <div class="space-y-4 rounded bg-white p-4 shadow">
                            <div class="h-4 w-1/2 rounded bg-gray-300"></div>
                            <div class="h-20 rounded bg-gray-200"></div>
                            <div class="h-6 w-1/4 rounded bg-gray-300"></div>
                        </div>
                        <div class="space-y-4 rounded bg-white p-4 shadow">
                            <div class="h-4 w-1/2 rounded bg-gray-300"></div>
                            <div class="h-20 rounded bg-gray-200"></div>
                            <div class="h-6 w-1/4 rounded bg-gray-300"></div>
                        </div>
                        <div class="space-y-4 rounded bg-white p-4 shadow">
                            <div class="h-4 w-1/2 rounded bg-gray-300"></div>
                            <div class="h-20 rounded bg-gray-200"></div>
                            <div class="h-6 w-1/4 rounded bg-gray-300"></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 3 Column wrapper -->
            <div v-if="!isLoading" class="flex-grow w-full">
                <div class="flex" v-if="!searchQuery">
                    <!-- Left sidebar & main wrapper -->
                    <div class="flex-1 min-w-0 xl:flex">
                        <!-- Left sidebar -->
                        <div
                            class="border-b border-gray-200 xl:border-b-0 xl:flex-shrink-0 xl:w-80 xl:border-r xl:border-gray-200">
                            <div class="h-full py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
                                <!-- Categories -->
                                <nav class="flex-1 space-y-1" aria-label="Categories">
                                    <button v-for="category in categories" :key="category.id"
                                        @click="selectedCategory = category.id" :class="[
                                            selectedCategory === category.id
                                                ? 'bg-indigo-50 border-indigo-600 text-indigo-600'
                                                : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                            'group w-full flex items-center px-3 py-3 text-sm font-medium border-l-4'
                                        ]">
                                        <component :is="category.icon" :class="[
                                            selectedCategory === category.id ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        ]" aria-hidden="true" />
                                        <span class="text-left text-sm">{{ category.name }}</span>
                                    </button>
                                </nav>
                            </div>
                        </div>

                        <!-- Main content -->
                        <div class="lg:min-w-0 lg:flex-1">
                            <div class="h-full py-6 px-4 sm:px-6 lg:px-8">
                                <!-- Template Grid -->
                                <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                    <div v-for="template in filteredTemplates" :key="template.id"
                                        @click="openTemplatePreview(template.id)"
                                        class="group relative bg-white rounded-2xl shadow-sm hover:shadow-md ring-1 ring-gray-200/50 hover:ring-gray-300/50 transition-all duration-200 overflow-hidden cursor-pointer">
                                        <!-- Template Image/Placeholder -->
                                        <div v-if="template.thumbnail" class="aspect-[16/9] w-full overflow-hidden">
                                            <img :src="template.thumbnail" :alt="template.title"
                                                class="h-full w-full object-cover object-center group-hover:scale-105 transition duration-300 ease-in-out" />
                                        </div>
                                        <div v-else
                                            class="aspect-[16/9] w-full bg-gradient-to-br from-gray-50 to-gray-100">
                                            <div class="flex items-center justify-center w-full h-full">
                                                <div class="flex flex-col items-center space-y-2">
                                                    <svg class="w-12 h-12 text-gray-300 transform group-hover:scale-110 transition-transform duration-300"
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="currentColor" viewBox="0 0 20 18">
                                                        <path
                                                            d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Template Info -->
                                        <div class="py-5 px-3">
                                            <div class="flex flex-col justify-between h-full">
                                                <div class="flex-1 space-y-2">
                                                    <h3
                                                        class="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">
                                                        {{ template.title }}
                                                    </h3>
                                                    <p class="text-sm text-gray-500 line-clamp-2 leading-relaxed">
                                                        {{ template.description }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Search results -->
                <div v-if="searchQuery && searchedTemplates.length > 0">
                    <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                        <div v-for="template in searchedTemplates" :key="template.id"
                            @click="openTemplatePreview(template.id)"
                            class="group relative bg-white rounded-2xl shadow-sm hover:shadow-md ring-1 ring-gray-200/50 hover:ring-gray-300/50 transition-all duration-200 overflow-hidden cursor-pointer">
                            <!-- Template Image/Placeholder -->
                            <div v-if="template.thumbnail" class="aspect-[16/9] w-full overflow-hidden">
                                <img :src="template.thumbnail" :alt="template.title"
                                    class="h-full w-full object-cover object-center group-hover:scale-105 transition duration-300 ease-in-out" />
                            </div>
                            <div v-else class="aspect-[16/9] w-full bg-gradient-to-br from-gray-50 to-gray-100">
                                <div class="flex items-center justify-center w-full h-full">
                                    <div class="flex flex-col items-center space-y-2">
                                        <svg class="w-12 h-12 text-gray-300 transform group-hover:scale-110 transition-transform duration-300"
                                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                            viewBox="0 0 20 18">
                                            <path
                                                d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <!-- Template Info -->
                            <div class="py-5 px-3">
                                <div class="flex flex-col justify-between h-full">
                                    <div class="flex-1 space-y-2">
                                        <h3
                                            class="text-sm font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">
                                            {{ template.title }}
                                        </h3>
                                        <p class="text-sm text-gray-500 line-clamp-2 leading-relaxed">
                                            {{ template.description }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="searchQuery && searchedTemplates.length === 0" class="text-center py-12 bg-gray-50 rounded-2xl">
                    <h3 class="mt-4 text-sm font-semibold text-gray-900">No templates found</h3>
                    <p class="mt-2 text-sm text-gray-500">
                        Try different keywords or browse our categories.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ChartBarIcon,
    UserGroupIcon,
    UserIcon,
    RocketLaunchIcon,
    HeartIcon,
    ChartPieIcon,
    ArrowTrendingUpIcon,
    LightBulbIcon,
    MagnifyingGlassIcon
} from '@heroicons/vue/24/outline'
import supabase from "../../../src/supabase"

export default {
    components: {
        MagnifyingGlassIcon,
        UserIcon,
        RocketLaunchIcon,
        HeartIcon,
        ChartBarIcon,
        ArrowTrendingUpIcon,
        ChartPieIcon,
        LightBulbIcon,
        UserGroupIcon
    },
    data() {
        return {
            selectedCategory: '',
            searchQuery: '',
            categories: [],
            templates: [],
            isLoading: true
        }
    },
    methods: {
        async fetchTemplates() {
            try {
                this.isLoading = true;
                const { data, error } = await supabase
                    .from('tenant_surveys')
                    .select('id, title, metadata')
                    .not('metadata', 'is', null);

                if (error) {
                    console.error("Error fetching templates:", error);
                    return;
                }

                // Extract unique categories from metadata
                const uniqueCategories = new Set();
                data.forEach(template => {
                    if (template.metadata?.category) {
                        template.metadata.category.split(',').forEach(category => {
                            uniqueCategories.add(category.trim());
                        });
                    }
                });

                // Map categories to our format with icons
                this.categories = Array.from(uniqueCategories).map(category => ({
                    id: category.toLowerCase().replace(/\s+/g, '_'),
                    name: category,
                    icon: this.getCategoryIcon(category)
                }));

                // Set first category as selected if none selected
                if (!this.selectedCategory && this.categories.length > 0) {
                    this.selectedCategory = this.categories[0].id;
                }

                // Process templates
                this.templates = data.map(template => ({
                    id: template.id,
                    title: template.title,
                    // Handle multiple categories
                    categories: template.metadata?.category?.split(',').map(cat =>
                        cat.trim().toLowerCase().replace(/\s+/g, '_')
                    ) || [],
                    description: template.metadata?.description || 'No description available',
                    thumbnail: template.metadata?.thumbnail || null,
                    preview_url: template.metadata?.preview_url || null
                }));

            } catch (err) {
                console.error("Unexpected error:", err);
            } finally {
                this.isLoading = false;
            }
        },

        getCategoryIcon(category) {
            const iconMap = {
                'build_user_persona': UserIcon,
                'improve_feature_adoption': RocketLaunchIcon,
                'gauge_customer_sentiment': HeartIcon,
                'improve_key_metrics': ChartBarIcon,
                'measure_impact_of_product_changes': ArrowTrendingUpIcon,
                'optimize_user_onboarding': RocketLaunchIcon,
                'measure_product_market_fit': ChartPieIcon,
                'inform_&_validate_product_roadmap': LightBulbIcon
            };

            const categoryId = category.toLowerCase().replace(/\s+/g, '_');
            return iconMap[categoryId] || ChartBarIcon; // Default to ChartBarIcon if no mapping found
        },

        openTemplatePreview(templateId) {
            window.open(`/templates/${templateId}`, '_blank');
        }
    },
    async mounted() {
        await this.fetchTemplates();
    },
    computed: {
        filteredTemplates() {
            let filtered = this.templates;
            if (this.selectedCategory) {
                filtered = filtered.filter(template =>
                    template.categories.includes(this.selectedCategory)
                );
            }
            return filtered;
        },
        searchedTemplates() {
            return this.templates.filter(template =>
                template.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                template.description?.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    watch: {
        selectedCategory() {
            // Clear search when category changes
            this.searchQuery = '';
        }
    }
}
</script>