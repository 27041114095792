<template>
  <div class="p-6 max-w-4xl mx-auto w-full">
    <GlobalNotification
      :key="ok"
      v-if="showSuccessNotification"
      :type="'ok'"
      :message="'Tenant invited successfully'"
      @close="showSuccessNotification = false"
    />
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-bold">Tenants</h2>
      <button
        type="button"
        class="btn-primary-sondar"
        @click="showPopup = true"
      >
        New
      </button>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-4 p-4">
        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>

        <div
          class="flex items-center justify-between rounded bg-gray-100 p-4 shadow"
        >
          <div class="space-y-2">
            <div class="h-4 w-1/3 rounded bg-gray-300"></div>
            <div class="h-3 w-1/2 rounded bg-gray-300"></div>
            <div class="h-3 w-1/3 rounded bg-gray-300"></div>
          </div>
          <div class="h-6 w-6 rounded bg-gray-300"></div>
        </div>
      </div>
    </div>

    <!-- Tenants List -->
    <div v-if="!isLoading">
      <div v-if="tenants.length === 0" class="text-center py-4 mt-10 bg-gray-100 rounded-lg shadow">
        <p class="text-gray-700 font-medium">There are no tenants. Add one now!</p>
      </div>

      <div v-else class="overflow-hidden">
        <ul role="list" class="divide-y divide-gray-200 border-t border-b border-gray-200">
          <li v-for="(tenant, index) in paginatedTenants" :key="index" class="py-4">
            <div class="flex justify-between items-center">
              <div class="min-w-0 flex-1 text-start">
                <h3 class="text-sm font-medium text-gray-900 truncate" :title="tenant.name">
                  {{ tenant.name }}
                </h3>
                <p class="text-sm text-gray-500">
                  Created At: {{ formatDate(tenant.created_at) }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0">
                <button
                  type="button"
                  class="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="deleteTenant(tenant.id)"
                >
                  <TrashIcon class="h-5 w-5" />
                  <span class="ml-2">Delete</span>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Pagination -->
    <div v-if="!isLoading && tenants.length > itemsPerPage" class="flex justify-between items-center mt-4">
      <button
        type="button"
        @click="previousPage"
        :disabled="currentPage === 1"
        class="btn-secondary-sondar"
      >
        Previous
      </button>
      <span class="text-sm font-medium text-gray-700">
        Page {{ currentPage }} of {{ totalPages }}
      </span>
      <button
        type="button"
        @click="nextPage"
        :disabled="currentPage === totalPages"
        class="btn-secondary-sondar"
      >
        Next
      </button>
    </div>

    <!-- New Tenant Modal -->
    <div v-if="showPopup" class="relative z-50">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>

      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <div
            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
          >
            <div>
              <h3 class="text-base font-semibold leading-6 text-gray-900">
                Invite a New Tenant
              </h3>
              <div class="py-4">
                <div class="mb-4">
                  <label
                    for="tenant-name"
                    class="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Organization Name
                  </label>
                  <div class="mt-2">
                    <input
                      id="tenant-name"
                      v-model="newTenantName"
                      type="text"
                      placeholder="e.g. Acme Corp"
                      @blur="validateOrganizationName"
                      class="input-sondar"
                    />
                    <p v-if="organizationNameError" class="mt-2 text-sm text-red-600">
                      {{ organizationNameError }}
                    </p>
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    for="admin-name"
                    class="block text-left text-sm font-medium leading-6 text-gray-900"
                  >
                    Admin Name
                  </label>
                  <div class="mt-2">
                    <input
                      id="admin-name"
                      v-model="newAdminName"
                      type="text"
                      placeholder="e.g. John Doe"
                      @blur="validateAdminName"
                      class="input-sondar"
                    />
                    <p v-if="adminNameError" class="mt-2 text-sm text-red-600">
                      {{ adminNameError }}
                    </p>
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    for="email"
                    class="block text-sm font-medium leading-6 text-left text-gray-900"
                  >
                    Admin Email
                  </label>
                  <div class="mt-2">
                    <input
                      id="email"
                      v-model="newEmail"
                      type="email"
                      placeholder="e.g. john@acmecorp.com"
                      @blur="validateEmail"
                      class="input-sondar"
                    />
                    <p v-if="adminEmailError" class="mt-2 text-sm text-red-600">
                      {{ adminEmailError }}
                    </p>
                  </div>
                </div>

                <p v-if="errorMessage" class="mt-2 text-sm text-red-600">
                  {{ errorMessage }}
                </p>
              </div>
            </div>

            <div
              class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
            >
              <button
                type="button"
                @click="closeModal()"
                class="btn-secondary-sondar"
              >
                Cancel
              </button>
              <button
                type="button"
                @click="createEntity"
                :disabled="isCreating || !isFormValid"
                class="btn-primary-sondar"
                :class="{ 'opacity-50 cursor-not-allowed': !isFormValid }"
              >
                <template v-if="isCreating">
                  <div
                    class="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mx-auto"
                  ></div>
                </template>
                <template v-else>Invite</template>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/24/outline";
import supabase from "../../../src/supabase";
import GlobalNotification from "../GlobalNotification.vue";

export default {
  components: {
    TrashIcon,
    GlobalNotification,
  },
  data() {
    return {
      tenants: [],
      isLoading: true,
      showPopup: false,
      newTenantName: "",
      newEmail: "",
      newAdminName: "",
      organizationNameError: "",
      adminNameError: "",
      adminEmailError: "",
      showSuccessNotification: false,
      errorMessage: "",
      itemsPerPage: 10,
      currentPage: 1,
      isCreating: false,
    };
  },
  computed: {
    paginatedTenants() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.tenants.slice(start, start + this.itemsPerPage);
    },
    totalPages() {
      return Math.ceil(this.tenants.length / this.itemsPerPage);
    },
    isFormValid() {
      // Check if all required fields are filled and valid
      const isEmailValid = this.newEmail && this.isValidEmail(this.newEmail);
      const isNameValid = this.newAdminName && this.newAdminName.length >= 2;
      const isOrgValid = this.newTenantName && this.newTenantName.length >= 2;
      
      return isEmailValid && isNameValid && isOrgValid;
    }
  },
  methods: {
    async fetchTenants() {
      this.isLoading = true;
      const { data, error } = await supabase
        .from("tenants")
        .select("*")
        .order("created_at", { ascending: false });
      
      if (!error) {
        this.tenants = data;
      } else {
        console.error("Error fetching tenants:", error);
      }
      this.isLoading = false;
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    closeModal() {
      this.newTenantName = "";
      this.newEmail = "";
      this.newAdminName = "";
      this.organizationNameError = "";
      this.adminNameError = "";
      this.adminEmailError = "";
      this.errorMessage = "";
      this.showPopup = false;
    },
    generateRandomKey(length = 40) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      return [...Array(length)]
        .map(() => characters[(Math.random() * charactersLength) | 0])
        .join("");
    },
    validateOrganizationName() {
      this.organizationNameError = "";
      if (!this.newTenantName) {
        this.organizationNameError = "Organization name is required";
      } else if (this.newTenantName.length < 2) {
        this.organizationNameError = "Organization name must be at least 2 characters";
      }
    },
    validateAdminName() {
      this.adminNameError = "";
      if (!this.newAdminName) {
        this.adminNameError = "Admin name is required";
      } else if (this.newAdminName.length < 2) {
        this.adminNameError = "Admin name must be at least 2 characters";
      }
    },
    validateEmail() {
      this.adminEmailError = "";
      if (!this.newEmail) {
        this.adminEmailError = "Email is required";
      } else if (!this.isValidEmail(this.newEmail)) {
        this.adminEmailError = "Please enter a valid email address";
      }
    },
    async createEntity() {
      this.errorMessage = "";
      this.organizationNameError = "";
      this.adminNameError = "";
      this.adminEmailError = "";
      this.isCreating = true;

      try {
        let hasError = false;

        // Organization name validation
        this.validateOrganizationName();
        if (this.organizationNameError) {
          hasError = true;
        }

        // Admin name validation
        this.validateAdminName();
        if (this.adminNameError) {
          hasError = true;
        }

        // Email validation
        this.validateEmail();
        if (this.adminEmailError) {
          hasError = true;
        }

        if (hasError) {
          return;
        }

        try {
          // Check if email already exists
          const { data: existingUser, error: checkError } = await supabase
            .from("users")
            .select("email")
            .eq("email", this.newEmail)
            .single();

          if (existingUser) {
            this.adminEmailError = "Cannot invite this user. This email is already registered.";
            return;
          }

          if (checkError && checkError.code !== 'PGRST116') {
            throw checkError;
          }

          const { data: tenantData, error: tenantError } = await supabase
            .from("tenants")
            .insert({
              name: this.newTenantName,
              api_key: this.generateRandomKey(),
            })
            .select()
            .single();

          if (tenantError) throw tenantError;

          const { data: userData, error: userError } = await supabase.auth.admin.inviteUserByEmail(
            this.newEmail,
            {
              redirectTo: `${window.location.protocol}//${window.location.host}/reset-password`,
            }
          );

          if (userError) throw userError;

          const { error: userTableError } = await supabase.from("users").insert({
            id: userData.user.id,
            email: this.newEmail,
            name: this.newAdminName,
            role_id: 2, //tenant_admin
            tenant_id: tenantData.id,
          });

          if (userTableError) throw userTableError;
        } catch (error) {
          this.errorMessage = error.message;
        }
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.newTenantName = "";
        this.newEmail = "";
        this.newAdminName = "";
        this.showPopup = false;
        this.isCreating = false;
        this.showSuccessNotification = true;
        await this.fetchTenants();
        setTimeout(() => {
          this.showSuccessNotification = false;
        }, 4000);
      }
    },
    deleteTenant(id) {
      console.log("Deleting tenant with ID:", id);
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
  },
  watch: {
    newTenantName() {
      this.validateOrganizationName();
    },
    newAdminName() {
      this.validateAdminName();
    },
    newEmail() {
      this.validateEmail();
    }
  },
  async mounted() {
    await this.fetchTenants();
  },
};
</script>

<style scoped></style>
