<template>
  <div class="text-left">
    <!-- Discard Modal -->
    <ConfirmationDialog :open="showDialog" title="Discard Changes"
      message="Are you sure you want to discard all unsaved changes? This action cannot be undone."
      confirmButtonText="Discard" cancelButtonText="Cancel" @close="showDialog = false"
      @confirm="handleDiscardConfirm" />
    <!-- Notification Component -->
    <GlobalNotification :key="copied" v-if="showCopiedNotification" :type="'ok'"
      :message="'Code snippet copied to clipboard'" />

    <!-- Loading -->
    <div v-if="isLoading">
      <div class="animate-pulse space-y-6 p-4">
        <!-- Trigger Modes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex justify-between space-x-4">
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
          </div>
        </div>

        <!-- URL Input Section -->
        <div class="flex space-x-4">
          <div class="h-10 w-1/6 rounded bg-gray-300"></div>
          <div class="h-10 flex-grow rounded bg-gray-300"></div>
        </div>

        <!-- User Attributes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="animate-pulse space-y-6 p-4">
        <!-- Trigger Modes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex justify-between space-x-4">
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
            <div class="h-10 w-1/3 rounded bg-gray-300"></div>
          </div>
        </div>

        <!-- URL Input Section -->
        <div class="flex space-x-4">
          <div class="h-10 w-1/6 rounded bg-gray-300"></div>
          <div class="h-10 flex-grow rounded bg-gray-300"></div>
        </div>

        <!-- User Attributes Section -->
        <div class="space-y-4">
          <div class="h-6 w-1/4 rounded bg-gray-300"></div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
            <div class="flex-1">
              <div class="h-6 w-1/2 rounded bg-gray-300"></div>
              <div class="mt-2 h-10 w-full rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="p-6 space-y-6">
      <!-- ======================= -->
      <!-- ======================= -->
      <!-- Triggers Section       -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div class="flex pt-5 pb-10 border-b border-gray-200">
        <!-- Left Column -->
        <div class="w-[300px] mr-5">
          <h2 class="text-base/7 font-semibold text-gray-900">Triggers</h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Define an action that will show this survey to your users
          </p>
        </div>

        <!-- Right Column -->
        <div class="space-y-4">
          <div class="flex space-x-4">
            <div @click="triggerSettings.type = 'page_visit'" :class="{
              'border-indigo-600 ring-2 ring-indigo-600':
                triggerSettings.type === 'page_visit',
              'hover:ring-1 hover:ring-gray-300':
                triggerSettings.type !== 'page_visit',
            }"
              class="group relative flex cursor-pointer rounded-lg text-left border border-gray-300 bg-white px-2 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600">
              <heroicon-outline-globe-alt class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Page Visit
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey when a user visits a specific web page
                </p>
              </div>
            </div>

            <div @click="triggerSettings.type = 'tracked_event'" :class="{
              'border-indigo-600 ring-2 ring-indigo-600':
                triggerSettings.type === 'tracked_event',
              'hover:ring-1 hover:ring-gray-300':
                triggerSettings.type !== 'tracked_event',
            }"
              class="group relative flex cursor-pointer rounded-lg text-left border border-gray-300 bg-white px-2 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600">
              <heroicon-outline-cursor-arrow-rays class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Tracked Event
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey when a user performs a specific action
                </p>
              </div>
            </div>

            <div @click="triggerSettings.type = 'manual'" :class="{
              'border-indigo-600 ring-2 ring-indigo-600':
                triggerSettings.type === 'manual',
              'hover:ring-1 hover:ring-gray-300':
                triggerSettings.type !== 'manual',
            }"
              class="group relative flex cursor-pointer rounded-lg text-left border border-gray-300 bg-white px-2 py-4 shadow-xs focus:outline-hidden data-focus:border-indigo-600 data-focus:ring-2 data-focus:ring-indigo-600">
              <heroicon-outline-code-bracket-square class="w-12 h-6 text-gray-900" />
              <div class="ml-1.5 mt-0.5">
                <h4 class="block text-sm font-semibold text-gray-900">
                  Manual
                </h4>
                <p class="text-sm text-gray-500 mt-1">
                  Show a survey by calling a method directly in the SDK
                </p>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <legend v-if="triggerSettings.type !== 'manual'" class="mb-2 text-sm text-left font-semibold text-gray-900">
              Conditions
            </legend>
            <legend v-if="triggerSettings.type === 'manual'" class="mb-2 text-sm text-left font-semibold text-gray-900">
              Code Snippet
            </legend>
            <div class="flex flex-col" v-if="triggerSettings.type === 'page_visit'">
              <div class="flex items-center space-x-2">
                <select v-model="triggerSettings.page_visit.selected"
                  class="rounded-md cursor-default bg-white p-1.5 text-left text-sm text-gray-900 border border-gray-300 focus:border-indigo-600 focus:border-2">
                  <option v-for="option in triggerSettings.page_visit.options" :value="option.value" :key="option">
                    {{ option.label }}
                  </option>
                </select>
                <input v-model="triggerSettings.page_visit.value" type="text" placeholder="https://myapp.com/profile"
                  :required="triggerSettings.page_visit.selected !== 'any'" :class="[
                    'w-full rounded-md p-1.5 text-sm border focus:outline-none focus:border-2 focus:border-indigo-600',
                    triggerSettings.page_visit.selected === 'any'
                      ? 'bg-gray-100 border-gray-200 text-gray-500'
                      : 'border-gray-300',
                    triggerSettings.page_visit.selected !== 'any' &&
                      !triggerSettings.page_visit.value
                      ? 'border-red-500 border-2'
                      : 'border-gray-300',
                  ]" />
              </div>
              <span v-if="
                triggerSettings.page_visit.selected !== 'any' &&
                !triggerSettings.page_visit.value
              " class="mt-2 text-sm font-medium text-red-700 validation-error text-left ml-32">This field is
                required.</span>
            </div>

            <div class="flex flex-col" v-if="triggerSettings.type === 'tracked_event'">
              <div class="space-y-4">
                <select v-model="triggerSettings.tracked_events.selected[0].event"
                  class="w-full rounded-md cursor-default p-1.5 text-left text-sm text-gray-900 border border-gray-300 focus:border-indigo-600 focus:border-2"
                  :class="[
                    !triggerSettings.tracked_events.selected[0].event
                      ? 'border-red-500'
                      : 'border-gray-300',
                  ]">
                  <option value="" disabled selected>Select event</option>
                  <option v-for="option in triggerSettings.tracked_events.options" :value="option.name" :key="option">
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="
                  !triggerSettings.tracked_events.selected[0].event ||
                  !triggerSettings.tracked_events.selected[0].value
                " class="text-left mt-3 text-red-700 validation-error text-sm font-medium">This field is
                  required.</span>
                <div class="flex items-center space-x-2">
                  <span class="text-sm text-gray-900">Show this survey when above event has occurred</span>
                  <input type="number" min="1" value="1" v-model="triggerSettings.tracked_events.selected[0].value"
                    :class="[
                      !triggerSettings.tracked_events.selected[0].value
                        ? 'border-red-500'
                        : 'border-gray-300',
                      'focus:border-indigo-600 focus:border-2 focus:outline-none',
                    ]" class="w-16 rounded-md cursor-default p-1.5 text-left text-sm text-gray-900 border" />
                  <span class="text-sm text-gray-900">time(s).</span>
                </div>
              </div>
            </div>

            <div class="p-2 rounded-md bg-indigo-50 border border-indigo-600" v-if="triggerSettings.type === 'manual'">
              <div class="px-4 py-2 font-mono text-sm whitespace-pre-wrap relative flex items-center justify-center">
                <code v-text="`SondarSDK.showSurvey('${survey_id}')`"></code>
                <button @click="
                  copyToClipboard(`SondarSDK.showSurvey('${survey_id}')`)
                  "
                  class="absolute right-2 p-1.5 rounded-md text-indigo-600 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  title="Copy to clipboard">
                  <heroicon-outline-document-duplicate class="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ======================= -->
      <!-- ======================= -->
      <!-- User Attributes Section -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div v-if="triggerSettings.type !== 'manual'" class="flex pt-5 pb-10 border-b border-gray-200">
        <!-- Left Column -->
        <div class="w-[286px] mr-5">
          <h2 class="text-base/7 font-semibold text-gray-900">
            User Attributes
          </h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Choose the audience for this survey
          </p>
        </div>

        <!-- Right Column -->
        <div class="w-full">
          <div class="space-y-6">
            <div class="">
              <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
                Match with
              </label>
              <select v-model="user_attributes_settings.matchOption"
                class="w-1/2 rounded-md cursor-default bg-white p-1.5 text-sm text-gray-900 border border-gray-300">
                <option value="all">All Attributes</option>
                <option value="any">Any Attribute</option>
              </select>
            </div>
            <div v-for="(
                attr, index
              ) in user_attributes_settings.defaultAttributes" :key="index" class="">
              <label class="text-sm/6 font-semibold text-gray-900 w-1/4">
                {{ attr.label }}:
              </label>
              <div class="flex space-x-2">
                <select :value="''" @change="(event) => addSelectedItem(event, index)"
                  class="w-1/2 rounded-md cursor-default bg-white p-1.5 text-sm text-gray-900 border border-gray-300">
                  <option value="" disabled>
                    {{ attr.selected.length > 0 ? "Select" : "Any" }}
                  </option>
                  <option v-for="option in attr.options" :key="option.value" :value="option.value">
                    {{ option.label }}
                  </option>
                </select>
                <div class="flex flex-wrap space-x-2">
                  <span v-for="(item, i) in attr.selected" :key="i"
                    class="inline-flex items-center gap-x-0.5 rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                    {{ item.label }}
                    <button type="button" @click="removeSelectedItem(attr, i)"
                      class="group relative -mr-1 h-3.5 w-3.5 hover:text-gray-800">
                      <span class="sr-only">Remove</span>
                      <svg viewBox="0 0 14 14" class="h-3.5 w-3.5">
                        <path d="M4 4l6 6m0-6l-6 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </button>
                  </span>
                </div>
                <button v-if="attr.selected.length > 0" @click="clearSelection(attr)"
                  class="text-sm font-medium text-indigo-600 hover:underline">
                  Reset
                </button>
              </div>
            </div>

            <!-- Custom Attributes  -->
            <div v-for="(attr, index) in user_attributes_settings.customAttributes
              .selected" :key="index" class="flex flex-col">
              <label class="text-sm/6 font-semibold text-gray-900">{{ attr.label }}:</label>
              <div class="flex space-x-2">
                <div class="flex w-1/2 space-x-2">
                  <select v-model="attr.operator"
                    class="w-1/3 rounded-md border-0 p-1.5 text-gray-900 ring-1 ring-inset sm:text-sm sm:leading-6"
                    :class="[
                      !attr.operator ? 'ring-red-500' : 'ring-gray-300', 'focus:ring-2 focus:ring-indigo-600'
                    ]">
                    <option disabled value="">Select...</option>
                    <option v-for="option in attr.options" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                  <input v-if="attr.type != 'bool'" v-model="attr.value" :type="attr.type"
                    class="flex-1 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6"
                    :class="[!attr.value ? 'ring-red-500' : 'ring-gray-300', 'focus:ring-2 focus:ring-indigo-600']" />
                </div>

                <button @click="removeCustomAttribute(index)" class="text-gray-500 hover:text-red-700 text-sm">
                  <heroicon-outline-trash class="w-5 h-5" />
                </button>
              </div>
                <span v-if="
                  !attr.operator ||
                  (attr.type != 'bool' ? !attr.value : false)
                " class="validation-error text-red-700 text-sm font-medium flex items-center">This field is required.</span>
            </div>
          </div>

          <div v-if="triggerSettings.type !== 'page_visit'" class="mt-6">
            <button @click="openAttributeModal"
              class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Add Attribute
            </button>
          </div>
        </div>

        <!-- Custome Attribute Modal -->
        <div v-if="isModalOpen" class="relative z-50">
          <!-- Background backdrop -->
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

          <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <!-- Header -->
                <div class="mb-4">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">Select Custom Attribute</h3>
                </div>

                <!-- Content -->
                <div class="space-y-2">
                  <template v-for="(attr, index) in user_attributes_settings.customAttributes.options" :key="index">
                    <div @click="selectCustomAttribute(attr)"
                      class="group relative flex items-center space-x-3 rounded-lg border border-gray-300 px-5 py-4 shadow-sm hover:border-indigo-600 hover:border-2 hover:bg-indigo-50 cursor-pointer">
                      <div class="min-w-0 flex-1">
                        <div class="flex items-center justify-between">
                          <span class="text-sm font-medium text-gray-900">{{ attr.label }}</span>
                          <span class="text-sm text-gray-500">({{ attr.type }})</span>
                        </div>
                      </div>
                      <span class="text-gray-400 group-hover:text-gray-500">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clip-rule="evenodd" />
                        </svg>
                      </span>
                    </div>
                  </template>
                </div>

                <!-- Footer -->
                <div class="mt-5 sm:mt-6">
                  <button type="button" @click="closeModal"
                    class="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- ======================= -->
      <!-- ======================= -->
      <!-- More Conditions Section -->
      <!-- ======================= -->
      <!-- ======================= -->
      <div class="flex pt-5 pb-10">
        <!-- Left Column -->
        <div class="w-[286px] mr-5">
          <h2 class="text-base/7 font-semibold text-gray-900">
            More Conditions
          </h2>
          <p class="mt-1 text-sm/6 text-gray-600">
            Fine tune when this survey will appear
          </p>
        </div>
        <!-- Right Column -->
        <div class="w-full space-y-6">
          <div class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Time Delay
            </label>
            <div class="flex items-center space-x-2">
              <input type="number" v-model="more_conditions.time_delay" :class="[
                more_conditions.time_delay === '' ? 'ring-red-500' : 'ring-gray-300',
                'focus:ring-2 focus:ring-indigo-600'
              ]"
                class="w-1/2 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6" />
              <span class="text-sm text-gray-900">Seconds</span>
            </div>
            <span v-if="more_conditions.time_delay === ''" class="mt-2 validation-error text-sm font-medium text-red-700">This field is required.</span>
          </div>

          <div class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Response Limit
            </label>
            <div class="flex items-center space-x-2">
              <select v-model="more_conditions.response_limit.limit"
                class="w-1/2 rounded-md border-0 px-1.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option :value="true">Set Limit</option>
                <option :value="false">No Limit</option>
              </select>
              <div v-if="more_conditions.response_limit.limit" class="flex-1 flex items-center space-x-2">
                <input type="number" v-model="more_conditions.response_limit.value" :class="[
                  !more_conditions.response_limit.value ? 'ring-red-500' : 'ring-gray-300',
                  'focus:ring-2 focus:ring-indigo-600'
                ]"
                  class="w-24 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6" />
                <span class="text-sm text-gray-900 whitespace-nowrap">Responses</span>
              </div>
            </div>
            <span v-if="more_conditions.response_limit.limit && !more_conditions.response_limit.value"
              class="mt-2 text-sm font-medium text-red-700 validation-error">This field is required.</span>
          </div>

          <div class="">
            <label class="text-sm/6 font-semibold text-gray-900 w-1/4 mb-1 flex items-center">
              Allow User to Dismiss Survey
            </label>
            <select v-model="more_conditions.can_dismiss"
              class="w-1/2 rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<!-- ==================== -->
<!-- ==================== -->
<!-- ==================== -->
<!--   START OF SCRIPT    -->
<!-- ==================== -->
<!-- ==================== -->
<!-- ==================== -->
<script>
import {
  CodeBracketSquareIcon,
  GlobeAltIcon,
  CursorArrowRaysIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/24/outline";
import GlobalNotification from "../GlobalNotification.vue";
import supabase from "../../../src/supabase";
import ConfirmationDialog from "../ConfirmationDialog.vue";
import { useAuthStore } from '@/stores/auth'

export default {
  components: {
    HeroiconOutlineCodeBracketSquare: CodeBracketSquareIcon,
    HeroiconOutlineGlobeAlt: GlobeAltIcon,
    HeroiconOutlineCursorArrowRays: CursorArrowRaysIcon,
    HeroiconOutlineTrash: TrashIcon,
    HeroiconOutlineDocumentDuplicate: DocumentDuplicateIcon,
    GlobalNotification,
    ConfirmationDialog,
  },
  name: "ConfigureSurvey",
  data() {
    return {
      debounceTimer: null,
      showDialog: false,
      previousBlocksState: null,
      transformedData: {},
      isLoading: true,
      survey_id: this.$route.params.survey_id,
      tenantId: "",
      isModalOpen: false,
      backup_state: {},
      dataOptionsMapping: {
        number: ["=", ">", "<", ">=", "<=", "!="],
        text: ["contains", "equals", "endsWith", "startsWith"],
        bool: ["true", "false"],
        date: ["=", ">", "<", ">=", "<=", "!="],
        "datetime-local": ["=", ">", "<", ">=", "<=", "!="],
      },
      more_conditions: {
        time_delay: 0,
        response_limit: {
          limit: false,
          value: 0,
        },
        can_dismiss: true,
      },
      user_attributes_settings: {
        matchOption: "all",
        defaultAttributes: [
          {
            label: "Language",
            attribute: "language",
            type: "dropdown",
            options: [],
            selected: [],
          },
          // {
          //     label: "Country",
          //     type: "dropdown",
          //     attribute: "country",
          //     options: [],
          //     selected: [],
          // },
          {
            label: "Device",
            attribute: "device",
            type: "dropdown",
            options: [
              {
                label: "Mobile",
                value: "mobile",
              },
              {
                label: "Desktop",
                value: "desktop",
              },
            ],
            selected: [],
          },
        ],
        customAttributes: {
          options: [],
          selected: [],
        },
      },
      triggerSettings: {
        type: "page_visit",
        page_visit: {
          options: [
            { value: "any", label: "Any" },
            { value: "exact_match", label: "Exact Match" },
            { value: "contains", label: "Contains" },
            { value: "starts_with", label: "Starts With" },
            { value: "ends_with", label: "Ends With" },
            { value: "regex", label: "Regex" },
          ],
          selected: "any",
          value: "",
        },
        tracked_events: {
          options: [],
          triggering_events: [],
          selected: [
            {
              event: "",
              operator: "=",
              attribute: "count",
              value: 1,
            },
          ],
        },
      },
      showCopiedNotification: false,
    };
  },
  async mounted() {
    const authStore = await useAuthStore();
    this.tenantId = authStore.tenantId;
    await this.fetchData();
    this.startChangeChecker();
  },
  watch: {
    "triggerSettings.page_visit.selected"(newValue) {
      if (newValue === "any") {
        this.triggerSettings.page_visit.value = "";
      }
    },
    "triggerSettings.type"(newValue) {
      if (newValue === "manual") {
        this.user_attributes_settings.defaultAttributes.forEach((attribute) => {
          attribute.selected = [];
        });
        this.user_attributes_settings.customAttributes.selected = [];
      } else if (newValue == "page_visit") {
        this.user_attributes_settings.customAttributes.selected = [];
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.checkChangesTimer);
  },
  methods: {
    async transformDataBeforeSave() {
      // Trigger Settings
      let conditions = [];
      let trigger_events = [];
      const trigger_type = this.triggerSettings.type;

      if (
        trigger_type === "page_visit" &&
        this.triggerSettings.page_visit.selected !== "any"
      ) {
        conditions = [
          {
            condition_type: "AND",
            operator: this.triggerSettings.page_visit.selected,
            value: this.triggerSettings.page_visit.value,
          },
        ];
      } else if (trigger_type === "tracked_event") {
        const eventDetails = this.triggerSettings.tracked_events.selected[0];
        conditions = [
          {
            condition_type: "AND",
            operator: eventDetails.operator,
            event: eventDetails.event,
            attribute: eventDetails.attribute,
            value: eventDetails.value,
          },
        ];
        trigger_events = [eventDetails.event];
      }

      // User Attributes
      let userAttributes = [];
      this.user_attributes_settings.defaultAttributes.forEach((attribute) => {
        if (attribute.selected.length > 0) {
          userAttributes.push({
            condition_type:
              this.user_attributes_settings.matchOption == "all" ? "AND" : "OR",
            operator: "in",
            attribute: attribute.attribute,
            value: attribute.selected.map((item) => item.value),
          });
        }
      });

      this.user_attributes_settings.customAttributes.selected.forEach(
        (attribute) => {
          userAttributes.push({
            condition_type:
              this.user_attributes_settings.matchOption == "all" ? "AND" : "OR",
            operator: attribute.type == "bool" ? "=" : attribute.operator,
            type: attribute.type,
            attribute: attribute.label,
            value:
              attribute.type == "bool" ? attribute.operator : attribute.value,
          });
        }
      );

      const updateData = {
        trigger_type: trigger_type,
        conditions: conditions,
        trigger_events: trigger_events,
        user_attrs: userAttributes,
        can_dismiss: this.more_conditions.can_dismiss,
        delay_seconds: this.more_conditions.time_delay,
        response_limit: this.more_conditions.response_limit.limit
          ? this.more_conditions.response_limit.value
          : null,
      };
      this.transformedData = updateData;
    },
    async syncBlocks() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        try {
          const { error } = await supabase
            .from("tenant_surveys")
            .update(this.transformedData)
            .eq("id", this.survey_id);

          if (error) {
            console.error("Error updating tenant_surveys:", error);
          }
        } catch (err) {
          console.error("Unexpected error:", err);
        }
      }, 2000);
    },
    async fetchData() {
      // Fetching the tenant created events
      try {
        const { data, error } = await supabase
          .from("tenant_event")
          .select("event_name")
          .eq("tenant_id", this.tenantId)
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching events:", error);
          return;
        }

        this.triggerSettings.tracked_events.options = data.map((event) => ({
          name: event.event_name,
        }));
      } catch (err) {
        console.error("Unexpected error:", err);
      }

      // Fetching the languages available in the db
      try {
        const { data, error } = await supabase
          .from("languages")
          .select("label, value");

        if (error) {
          throw new Error(error.message);
        }

        this.user_attributes_settings.defaultAttributes[0].options = data.map(
          (lang) => ({
            label: lang.label,
            value: lang.value,
          })
        );
      } catch (err) {
        console.error("Error fetching languages:", err);
      }

      // Fetching the countries available in the db
      // try {
      //     const { data, error } = await supabase
      //         .from('countries')
      //         .select('label, value');

      //     if (error) {
      //         throw new Error(error.message);
      //     }

      //     this.user_attributes_settings.defaultAttributes[1].options = data.map((country) => ({
      //         label: country.label,
      //         value: country.value,
      //     }));
      // } catch (err) {
      //     this.error = err.message;
      //     console.error('Error fetching countries:', err);
      // }

      // Fetching the tenant created user attributes
      try {
        const { data, error } = await supabase
          .from("tenant_user_attributes")
          .select("attribute_name, data_type")
          .eq("tenant_id", this.tenantId)
          .order("created_at", { ascending: false });

        if (error) {
          console.error("Error fetching events:", error);
          return;
        }

        this.user_attributes_settings.customAttributes.options = data.map(
          (event) => ({
            label: event.attribute_name,
            type: event.data_type,
            options: this.dataOptionsMapping[event.data_type],
            operator: "",
            value: "",
          })
        );
      } catch (err) {
        console.error("Unexpected error:", err);
      }

      // Fetching survey data to prefill the form
      try {
        const { data, error } = await supabase
          .from("tenant_surveys")
          .select(
            "conditions, user_attrs, trigger_type, can_dismiss, delay_seconds, response_limit"
          )
          .eq("id", this.survey_id)
          .single();

        this.setInitialSurveyData(data);

        if (error) {
          console.error("Error fetching events:", error);
          return;
        }
      } catch (err) {
        console.error("Unexpected error:", err);
      }
    },
    setInitialSurveyData(data) {
      // More conditions
      if (data.delay_seconds !== undefined) {
        this.more_conditions.time_delay = data.delay_seconds;
      }

      if (data.response_limit) {
        this.more_conditions.response_limit = {
          limit: true,
          value: data.response_limit,
        };
      }

      if (data.can_dismiss !== undefined) {
        this.more_conditions.can_dismiss = data.can_dismiss;
      }

      // User attributes
      if (data.user_attrs && data.user_attrs.length > 0) {
        this.user_attributes_settings.matchOption =
          data.user_attrs[0].condition_type == "AND" ? "all" : "any";
        // Default attributes
        var default_attrs = this.user_attributes_settings.defaultAttributes.map(
          (item) => item.attribute
        );
        default_attrs.forEach((ele, index) => {
          var def_attr = data.user_attrs.find((attr) => attr.attribute == ele);
          if (def_attr) {
            this.user_attributes_settings.defaultAttributes[index].selected =
              this.user_attributes_settings.defaultAttributes[
                index
              ].options.filter((item) => def_attr.value.includes(item.value));
          }
        });

        // Custom attributes
        var custom_attrs = data.user_attrs.filter(
          (item) => !default_attrs.includes(item.attribute)
        );
        custom_attrs.forEach((obj) => {
          this.user_attributes_settings.customAttributes.selected.push({
            label: obj.attribute,
            type: obj.type,
            options: this.dataOptionsMapping[obj.type],
            operator: obj.type == "bool" ? obj.value : obj.operator,
            value: obj.value,
          });
        });
      }

      // Trigger settings
      if (data.trigger_type !== undefined) {
        this.triggerSettings.type = data.trigger_type;
      }

      if (data.trigger_type == "tracked_event") {
        var tracked_event_condition = data.conditions[0];
        this.triggerSettings.tracked_events.triggering_events = [
          tracked_event_condition.event,
        ];
        this.triggerSettings.tracked_events.selected[0].event =
          tracked_event_condition.event;
        this.triggerSettings.tracked_events.selected[0].value =
          tracked_event_condition.value;
      }

      if (data.trigger_type == "page_visit" && data.conditions.length > 0) {
        var url_condition = data.conditions[0];
        this.triggerSettings.page_visit.selected = url_condition.operator;
        this.triggerSettings.page_visit.value = url_condition.value;
      }
      this.backup_state = {
        trigger_settings: JSON.parse(JSON.stringify(this.triggerSettings)),
        more_conditions: JSON.parse(JSON.stringify(this.more_conditions)),
        user_attributes_settings: JSON.parse(
          JSON.stringify(this.user_attributes_settings)
        ),
      };
      this.isLoading = false;
    },
    clearSelection(attr) {
      attr.selected = [];
    },
    addSelectedItem(event, index) {
      const selectedOption = this.user_attributes_settings.defaultAttributes[
        index
      ].options.find((option) => option.value === event.target.value);

      if (
        !this.user_attributes_settings.defaultAttributes[index].selected.some(
          (selected) => selected.value === selectedOption.value
        )
      ) {
        this.user_attributes_settings.defaultAttributes[index].selected.push(
          selectedOption
        );
      }

      event.target.value = "";
    },
    removeSelectedItem(attr, index) {
      attr.selected.splice(index, 1);
    },
    removeAttribute(index) {
      this.attributes.splice(index, 1);
    },
    openAttributeModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    removeCustomAttribute(index) {
      this.user_attributes_settings.customAttributes.selected.splice(index, 1);
    },
    selectCustomAttribute(option) {
      this.user_attributes_settings.customAttributes.selected.push({
        label: option.label,
        type: option.type,
        options: option.options,
        operator: "",
        value: "",
      });
      this.closeModal();
    },
    saveChanges() {
      const errorFields = document.querySelectorAll(".validation-error");
      const hasErrors = Array.from(errorFields).some(
        (field) => window.getComputedStyle(field).display !== "none"
      );
      if (hasErrors) {
        this.showErrorNotification = true;
        setTimeout(() => {
          this.showErrorNotification = false;
        }, 3000);
      } else {
        this.transformDataBeforeSave();
      }
    },
    handleDiscardConfirm() {
      this.showDialog = false;
      this.triggerSettings = this.backup_state.trigger_settings;
      this.more_conditions = this.backup_state.more_conditions;
      this.user_attributes_settings =
        this.backup_state.user_attributes_settings;
    },
    async copyToClipboard(text) {
      try {
        if (navigator.clipboard && window.isSecureContext) {
          await navigator.clipboard.writeText(text);
        } else {
          // Fallback for older browsers
          const textArea = document.createElement("textarea");
          textArea.value = text;
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
            document.execCommand("copy");
            textArea.remove();
          } catch (err) {
            console.error("Failed to copy text: ", err);
          }
        }
        // Show notification
        this.showCopiedNotification = true;
        setTimeout(() => {
          this.showCopiedNotification = false;
        }, 3000);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
    startChangeChecker() {
      this.transformDataBeforeSave();
      this.previousBlocksState = JSON.stringify(this.transformedData);
      this.checkChangesTimer = setInterval(() => {
        this.transformDataBeforeSave();
        const currentBlocksState = JSON.stringify(this.transformedData);
        const errorFields = document.querySelectorAll(".validation-error");
        const hasErrors = Array.from(errorFields).some(
          (field) => window.getComputedStyle(field).display !== "none"
        );
        if (
          this.previousBlocksState !== currentBlocksState && !hasErrors
        ) {
          this.previousBlocksState = currentBlocksState;
          this.syncBlocks();
        }
      }, 500);
    },
  },
};
</script>

<style scoped>
select {
  outline: none;
  cursor: pointer;
}

button {
  outline: none;
}
</style>