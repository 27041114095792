<template>
  <div
    v-if="show"
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          :class="[
            'pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5',
            notificationColor
          ]"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="shrink-0">
                <component :is="notificationIcon" class="size-6" :class="iconColor" aria-hidden="true" />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm text-left font-medium text-gray-900">{{ message }}</p>
              </div>
              <div class="ml-4 flex shrink-0">
                <button
                  type="button"
                  @click="closeNotification"
                  class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    XMarkIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => ['ok', 'warning', 'error'].includes(value),
    },
  },
  name: 'GlobalNotification',
  data() {
    return {
      show: true,
    };
  },
  computed: {
    notificationColor() {
      switch (this.type) {
        case 'ok':
          return 'bg-green-100';
        case 'warning':
          return 'bg-yellow-100';
        case 'error':
          return 'bg-red-100';
        default:
          return 'bg-white';
      }
    },
    iconColor() {
      switch (this.type) {
        case 'ok':
          return 'text-green-400';
        case 'warning':
          return 'text-yellow-400';
        case 'error':
          return 'text-red-400';
        default:
          return 'text-gray-400';
      }
    },
    notificationIcon() {
      switch (this.type) {
        case 'ok':
          return CheckCircleIcon;
        case 'warning':
          return ExclamationTriangleIcon;
        case 'error':
          return ExclamationCircleIcon;
        default:
          return CheckCircleIcon;
      }
    },
  },
  methods: {
    closeNotification() {
      this.show = false;
    },
  },
};
</script>
