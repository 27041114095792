<template>
  <div class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-10 w-auto" src="../../assets/full-logo.svg" alt="Your Company" />
      <h2 class="mt-6 text-center text-2xl/9 font-bold tracking-tight text-gray-900">Reset your password</h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <form @submit.prevent="handlePasswordReset" class="space-y-6">
          <div>
            <label for="email" class="block text-left text-sm/6 font-medium text-gray-900">Email address</label>
            <div class="mt-2">
              <input v-model="email" type="email" name="email" id="email" autocomplete="email" required
                @input="validateEmail"
                class="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" />
              <p v-if="emailError" class="mt-1 text-left text-sm text-red-600">{{ emailError }}</p>
            </div>
          </div>
          <div>
            <button type="submit"
              class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <span v-if="!loading">Reset Password</span>
              <span v-else
                class="animate-spin inline-block w-4 h-4 p-2.5 border-2 border-white border-t-transparent rounded-full"></span>
            </button>
          </div>

          <p v-if="formMessage" class="mt-4 text-sm text-gray-700">{{ formMessage }}</p>
          <p v-if="formError" class="mt-4 text-sm text-red-600">{{ formError }}</p>
        </form>
        <div class="mt-4">
            <p class="text-sm text-gray-600">
              <router-link to="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                Back to Sign In
              </router-link>
            </p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import supabase from '../../../src/supabase';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: '',
      emailError: '',
      formMessage: '',
      formError: '',
      loading: false,
    };
  },
  methods: {
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailError = emailRegex.test(this.email) ? '' : 'Please enter a valid email address';
    },
    async handlePasswordReset() {
      this.emailError = '';
      this.formMessage = '';
      this.formError = '';
      this.loading = true;

      if (!this.email) {
        this.emailError = 'Email is required';
        this.loading = false;
        return;
      }

      try {
        const { error } = await supabase.auth.resetPasswordForEmail(this.email, {
          redirectTo: `${window.location.protocol}//${window.location.host}/reset-password`,
        });

        if (error) {
          this.formError = error.message;
          this.loading = false;
          return;
        }

        this.formMessage = 'If an account exists with this email, you will receive a password reset link.';
      } catch (err) {
        this.formError = 'An unexpected error occurred. Please try again later.';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>