<template>
  <div class="chat-box bg-white rounded-2xl px-4 py-12 pb-6 sm:px-20 w-full shadow max-w-2xl flex flex-col items-center"
    :class="{ 'widget-shadow': isSDK }">
    <svg v-if="isSDK && canDismiss" @click="closeWidget" xmlns="http://www.w3.org/2000/svg" fill="none"
      viewBox="0 0 24 24" stroke-width="2"
      class="size-6 absolute top-2 right-2 cursor-pointer stroke-gray-400 hover:stroke-gray-700">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>

    <transition name="fade-slide">
      <div class="chat-content w-full flex flex-col items-center mx-auto text-center" v-if="currentContent">
        <p class="progress-text text-gray-500 text-sm font-medium">{{ currentIndex + 1 }} of {{ contentData.length }}
        </p>
        <div class="w-full flex flex-col items-center" v-if="currentContent.type === 'message'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'explore'">
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <FollowUp class="mt-4" @conversationStarted="handleConversationStarted" @chatEnded="handleChatEnd"
            :responseId="responseId" :blockId="currentContent.id" :openingMessage="currentContent.question"
            :key="`${currentContent.id}-${currentIndex}`" :fuQuestionsRemaining="currentContent.followup_questions" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'select'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice === choice }]"
              @click="!showFollowUp && selectOption(choice)">
              <input type="radio" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'rate'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="inline-block ratings-sondar">
            <div class="flex flex-row gap-x-1 sm:gap-x-3 pb-1">
              <label v-for="n in currentContent.max_value" :key="n" class="rating-label-sondar">
                <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n" :disabled="showFollowUp"
                  class="rating-input-sondar" />
                <!-- <span class="rating-star">&#9733;</span> -->
                <span :class="{ 'rating-text-sondar': true, 'rating-selected-sondar': selectedChoice === n }">{{ n }}</span>
              </label>
            </div>
            <div class="flex justify-between text-gray-500 font-medium mt-3">
              <div>{{ currentContent.min_label }}</div>
              <div>{{ currentContent.max_label }}</div>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div class="w-full flex flex-col items-center" v-else-if="currentContent.type === 'csat'">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="inline-block ratings-sondar">
            <div class="flex flex-row gap-x-1 sm:gap-x-3 pb-1">
              <label v-for="n in currentContent.max_value" :key="n" class="rating-label-sondar">
                <input type="radio" v-model="selectedChoice" :name="currentContent.id" :value="n" :disabled="showFollowUp"
                  class="rating-input-sondar" />
                <span v-if="currentContent.max_value === 5"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😟', '😐', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 4"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😟', '🙂', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 3"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['😠', '😐', '😍'][n - 1] }}
                </span>

                <span v-if="currentContent.max_value === 2"
                  :class="{ 'rating-csat-sondar': true, 'rating-selected-sondar': selectedChoice === n }">
                  {{ ['👎', '👍'][n - 1] }}
                </span>

              </label>
            </div>
          </div>

          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>

        <div v-else-if="currentContent.type === 'multi_choice'" class="w-full">
          <p class="block-headline-sondar">{{ currentContent.question }}</p>
          <p v-if="currentContent.description" class="block-description-sondar" v-html="currentContent.description"></p>
          <div class="options">
            <div v-for="(choice, index) in currentContent.choices" :key="index"
              :class="['option', { selected: selectedChoice && selectedChoice.includes(choice) }]"
              @click="!showFollowUp && toggleChoice(choice)">
              <input type="checkbox" :name="currentContent.id" :value="choice" v-model="selectedChoice"
                :disabled="showFollowUp" />
              <label>{{ choice }}</label>
            </div>
          </div>
          <FollowUp v-if="showFollowUp" style="margin-top: 1rem;" :responseId="responseId" @chatEnded="handleChatEnd"
            :openingMessage="followUpQuestionFromResponse" :blockId="currentContent.id"
            :key="`${currentContent.id}-${currentIndex}`" :messageId="blochMessageId"
            :fuQuestionsRemaining="currentContent.followup_questions - 1" />
        </div>
        <button v-if="showNextButton" :disabled="!isButtonEnabled"
          :class="['btn-primary-sondar mt-8', { 'btn-primary-disabled-sondar': !isButtonEnabled }]" class="btn-primary-sondar" @click="nextContent">{{
            currentContent.button_label }}</button>
      </div>
    </transition>

    <a href="https://sondar.ai" target="_blank" class="inline-block text-sm mt-4 underline text-gray-500">Powered by
      Sondar</a>
  </div>
</template>

<script>
import axios from 'axios';
import FollowUp from './FollowUp.vue';

export default {
  name: 'ChatBox',
  components: {
    FollowUp
  },
  props: {
    contentData: {
      type: Array,
      required: true
    },
    responseId: {
      type: String,
      required: true
    },
    surveyId: {
      type: String,
      required: false
    },
    isSDK: {
      type: Boolean,
      default: false
    },
    canDismiss: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentIndex: 0,
      selectedChoice: null,
      hasConversationStarted: false,
      hasChatEndedForCurrentQuestion: false,
      showFollowUp: false,
      followUpQuestionFromResponse: null,
      hasPostedForCurrentQuestion: false,
      blochMessageId: null,
    };
  },
  computed: {
    showNextButton() {
      if (this.isLastQuestion() && this.contentData[this.currentIndex].button_label == "") {
        return false;
      }
      return true;
    },
    isButtonEnabled() {
      let current = this.contentData[this.currentIndex];
      if (!current.required) return true;
      if (current.type === "message") {
        return true;
      } else if (current.type === "explore") {
        return this.hasConversationStarted;
      } else if (current.type === "multi_choice") {
        return this.selectedChoice.length > 0;
      } else {
        return this.selectedChoice !== null;
      }
    },
    currentContent() {
      return this.contentData[this.currentIndex];
    }
  },
  methods: {
    isLastQuestion() {
      return this.currentIndex === this.contentData.length - 1;
    },
    closeWidget() {
      const sondarWidget = document.getElementById('sondar-widget');
      if (sondarWidget) {
        sondarWidget.style.display = 'none';
      }
    },
    async postResponse(currentBlock) {
      const apiUrl = `${process.env.VUE_APP_SUPABASE_EF_BASE_URL}/save-response-item`;
      const data = {
        response_id: this.responseId,
        block_id: currentBlock.id,
        value: String(this.selectedChoice),
      };

      try {
        let calling_idx = this.currentIndex;
        const response = await axios.post(apiUrl, data);
        if (currentBlock.followup_questions && calling_idx === this.currentIndex) {
          this.blochMessageId = response.data.message_id;
          this.followUpQuestionFromResponse = response.data.message;
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }
    },
    handleChatEnd() {
      this.hasChatEndedForCurrentQuestion = true;
    },
    handleConversationStarted() {
      this.hasConversationStarted = true;
    },
    hasSelectedChoice() {
      // relying on required field to determine if a choice has been made
      let current = this.contentData[this.currentIndex];
      if (current.type === 'multi_choice') {
        return this.selectedChoice.length > 0;
      } else {
        return this.selectedChoice !== null;
      }
    },
    nextContent() {
      if (this.contentData[this.currentIndex].button_url) {
        let url = this.contentData[this.currentIndex].button_url;
        if (!/^https?:\/\//i.test(url)) {
          url = 'https://' + url;
        }
        window.open(url, '_blank');
      }
      if (this.isLastQuestion()) {
        this.closeWidget();
        return;
      }
      const current = this.contentData[this.currentIndex];

      if (!this.hasPostedForCurrentQuestion && current.type != 'explore' && current.type != 'message' && this.hasSelectedChoice()) {
        this.postResponse(current);
        this.hasPostedForCurrentQuestion = true;
        if (current.followup_questions) {
          this.showFollowUp = true;
          return;
        }
      }

      // Move to next question if no follow-up is required or chat ended
      this.currentIndex++;
      this.hasChatEndedForCurrentQuestion = false;
      this.showFollowUp = false;
      this.blochMessageId = null;
      this.hasPostedForCurrentQuestion = false;
      this.selectedChoice = this.contentData[this.currentIndex].type === "multi_choice" ? [] : null;
      this.followUpQuestionFromResponse = null;
    },
    selectOption(choice) {
      this.selectedChoice = choice;
    },
    toggleChoice(choice) {
      if (this.selectedChoice.includes(choice)) {
        this.selectedChoice = this.selectedChoice.filter(c => c !== choice);
      } else {
        this.selectedChoice.push(choice);
      }
    }
  },
  mounted() {
    this.selectedChoice = this.contentData[this.currentIndex].type === "multi_choice" ? [] : null;
  }
}
</script>

<style scoped>
.option input[type="radio"],
.option input[type="checkbox"] {
  display: none;
}

.option label {
  cursor: pointer;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  resize: none;
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-slide-enter,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(1rem);
}
</style>